import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { post } from "../../../api/BaseRequest";
import { pathnameCode, titleName } from "../../../utils";
import { detailTitlePost } from "../../../utils/redux/slices/TitlePostSlices";

const CreateTitle = () => {
  //redux
  const dispatch = useDispatch();
  const { detailTitle, loading } = useSelector((state) => state.listTitlePost);

  const [form] = Form.useForm();

  const { id, idPost } = useParams();

  const history = useHistory();

  useEffect(() => {
    if (idPost) {
      dispatch(detailTitlePost({ title: pathnameCode(id), id: idPost }));
    }
  }, [idPost, dispatch]);

  useEffect(() => {
    if (idPost) {
      form.setFieldsValue({
        postTitle: detailTitle.postTitle,
        status: detailTitle.status,
      });
    }
  }, [detailTitle]);

  const onFinish = (value) => {
    const newData = {
      ...value,
      title: pathnameCode(id),
    };
    if (idPost) {
      editTitlePost(newData);
    } else {
      createTitlePost(newData);
    }
  };

  const postTitle = (data) => post(`postTitle`, data);

  const { mutate: createTitlePost, isLoading: isPostingProduct } = useMutation(
    postTitle,
    {
      onSuccess: (data) => {
        form.resetFields();
        notification.success({ message: data.message });
      },
      onError: (error) => {
        console.log(error);
        notification.success({ message: "Lỗi từ server" });
      },
    }
  );

  const editTitlePost = (data) => {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/patchTitle`,
        {
          ...data,
        },
        {
          params: {
            id: id,
          },
        }
      )
      .then((res) => {
        notification.success({ message: "Chỉnh sửa thành công" });
        history.push(`/admin/title/${id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    history.push(`/admin/title/${id}`);
  };

  return (
    <Spin tip="Đang tải....." spinning={isPostingProduct || loading}>
      <div className="wrapper-form">
        <Row>
          <Col className="name-title-form">
            <Typography.Title level={3}>{`${
              idPost ? "Chỉnh sửa" : "Tạo mới"
            } ${titleName(id)}`}</Typography.Title>
          </Col>
        </Row>

        <Form form={form} onFinish={onFinish} labelCol={{ span: 2 }}>
          <Form.Item
            name="postTitle"
            label="Tên tiêu đề"
            rules={[
              {
                required: true,
                message: "Trường này cần nhập",
              },
            ]}
          >
            <Input placeholder="Nhập tên tiêu đề" />
          </Form.Item>

          <Form.Item
            name="status"
            label="Trạng thái"
            rules={[
              {
                required: true,
                message: "Trường này cần chọn",
              },
            ]}
          >
            <Select
              placeholder="Chọn trạng thái ẩn/hiển thị"
              options={[
                {
                  value: 0,
                  label: "Ẩn",
                },
                {
                  value: 1,
                  label: "Hiển thị",
                },
              ]}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Space>
              <Button type="primary" htmlType="submit">
                {idPost ? "Chỉnh sửa" : "Tạo mới"}
              </Button>
              <Button type="primary" danger onClick={handleCancel}>
                Hủy
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default CreateTitle;
