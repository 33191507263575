import React, { useState } from "react";
import { Col, Form, Input, Row, Button, notification, Spin } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from "react-query";
import { io } from "socket.io-client";

import { post } from "../../../api/BaseRequest";
import Banner from "../../../components/HomePage/Banner";
import ModalConfirm from "./ModalConfirm";

import "./style.css";

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
});

const Contract = () => {
  const [form] = Form.useForm();

  //state
  const [captcha, setCaptcha] = useState();
  const [openModal, setOpenModal] = useState(false);

  const onFinish = (value) => {
    postContractAPI(value);
    socket.emit("contact");
  };

  const onChangeCaptra = (captcha) => {
    setCaptcha(captcha);
  };

  const postContract = (data) => post(`contract`, data);

  const { mutate: postContractAPI, isLoading: isPostingContract } = useMutation(
    postContract,
    {
      onSuccess: (data) => {
        setOpenModal(true);
      },
      onError: (error) => {
        notification.error({ message: error.response.data.message });
      },
    }
  );

  return (
    <>
      <Spin tip="Đang gửi....." spinning={isPostingContract}>
        <div>
          <Banner />
          <div className="wrapper">
            <Row className="rowContent">
              <Col xxl={10} xl={12} lg={24} md={24} className="colContent">
                <h1 className="infoName">VIETTEL HỒ CHÍ MINH</h1>
                <p style={{ marginBottom: 10 }}>
                  285 CMT8, Phường 12, Quận 10, Tp Hồ Chí Minh.
                </p>
                <span className="lineContent">
                  <p>T</p>
                  <a href="tel:0963.003.606">0963.003.606</a>
                </span>
                <span className="lineContent">
                  <p>E</p>
                  <a href="mailto:vietteltphcm.online@gmail.com">
                    vietteltphcm.online@gmail.com
                  </a>
                </span>
                <span className="lineContent">
                  <p>W</p>
                  <a href="https://vietteltphcm.online/">vietteltphcm.online</a>
                </span>
              </Col>
              <Col xxl={8} xl={12} lg={24} md={24} className="colContent">
                <Form form={form} layout="vertical" onFinish={onFinish}>
                  <Form.Item name="name" label="Họ Tên">
                    <Input placeholder="Nhập họ và tên" />
                  </Form.Item>

                  <Form.Item
                    name="phone"
                    label="Điện thoại"
                    rules={[
                      {
                        required: true,
                        message: "Bạn cần điền trường này",
                      },
                    ]}
                  >
                    <Input placeholder="Nhập số điện thoại" />
                  </Form.Item>

                  <Form.Item name="email" label="Email">
                    <Input placeholder="Nhập email" />
                  </Form.Item>

                  <Form.Item
                    name="content"
                    label="Nội dung liên lạc"
                    rules={[
                      {
                        required: true,
                        message: "Bạn cần điền trường này",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={5}
                      placeholder="Nhập nội dung liên lạc"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Row
                      xxl={24}
                      xl={24}
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col span={24}>
                        <ReCAPTCHA
                          sitekey="6LciVbUhAAAAAC3TnEnMA2AGa3DS1zaoQkfnDjJ0"
                          onChange={onChangeCaptra}
                        />
                      </Col>
                      <Col span={24}>
                        <Button
                          type="primary"
                          loading={isPostingContract}
                          disabled={!captcha}
                          className="buttonSubmit"
                          htmlType="submit"
                        >
                          Gửi
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
      <ModalConfirm
        isModalConfirm={openModal}
        setIsModalConfirm={setOpenModal}
      />
    </>
  );
};

export default Contract;
