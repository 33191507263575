import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../api/BaseRequest";

export const listPost = createAsyncThunk("list_post", async () => {
  const { listPost } = await get("posts");
  return listPost;
});

export const postIds = createAsyncThunk("postId", async (id, value) => {
  const { postId } = await get(`posts/${id}`, value);
  return postId;
});

export const postCategorys = createAsyncThunk("postCategory", async (value) => {
  const { postCategory } = await get(`postCategory`, value);
  return postCategory;
});

const initialState = {
  loading: false,
  listPosts: [],
  postId: {},
  postCategory: {}
};

const ListPostSlices = createSlice({
  name: "listPost",
  initialState,
  extraReducers: (builder) => {
    //listpost
    builder.addCase(listPost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listPost.fulfilled, (state, action) => {
      state.listPosts = action.payload;
      state.loading = false;
    });
    builder.addCase(listPost.rejected, (state) => {
      state.loading = false;
    });
    //postid
    builder.addCase(postIds.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postIds.fulfilled, (state, action) => {
      state.postId = action.payload;
      state.loading = false;
    });
    builder.addCase(postIds.rejected, (state) => {
      state.loading = false;
    });
    //postcategory
    builder.addCase(postCategorys.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postCategorys.fulfilled, (state, action) => {
      state.postCategory = action.payload;
      state.loading = false;
    });
    builder.addCase(postCategorys.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ListPostSlices.reducer;
