import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../api/BaseRequest";

export const listEInvoice = createAsyncThunk("eInvoice", async (value) => {
  const { products } = await get(`e_invoices`, value);
  return products;
});

export const listInternetE = createAsyncThunk("internetE", async (value) => {
  const { products } = await get(`internet-wifi`, value);
  return products;
});

export const listMobileService = createAsyncThunk(
  "mobile_service",
  async (value) => {
    const { products } = await get(`mobile`, value);
    return products;
  }
);

export const listSolution = createAsyncThunk("solutions", async (value) => {
  const { products } = await get(`company`, value);
  return products;
});

export const listTeleDevi = createAsyncThunk("tele_devi", async (value) => {
  const { products } = await get(`devides`, value);
  return products;
});

export const listTelevison = createAsyncThunk("televisons", async (value) => {
  const { products } = await get(`televisons`, value);
  return products;
});

export const listViettelSign = createAsyncThunk(
  "viettel_sign",
  async (value) => {
    const { products } = await get(`viettel_sign`, value);
    return products;
  }
);

const initialState = {
  loading: false,
  search: "",
  e_invoice: [],
  internetI: [],
  internetE: [],
  mobile_service: [],
  solution: [],
  tele_devi: [],
  televisons: [],
  viettel_sign: [],
};

const ListProductsSlices = createSlice({
  name: "listProduct",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    //eInvoice
    builder.addCase(listEInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listEInvoice.fulfilled, (state, action) => {
      state.e_invoice = action.payload;
      state.loading = false;
    });
    builder.addCase(listEInvoice.rejected, (state) => {
      state.loading = false;
    });
    //internetExternal
    builder.addCase(listInternetE.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listInternetE.fulfilled, (state, action) => {
      state.internetE = action.payload;
      state.loading = false;
    });
    builder.addCase(listInternetE.rejected, (state) => {
      state.loading = false;
    });
    //mobile_service
    builder.addCase(listMobileService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listMobileService.fulfilled, (state, action) => {
      state.mobile_service = action.payload;
      state.loading = false;
    });
    builder.addCase(listMobileService.rejected, (state) => {
      state.loading = false;
    });
    //solution
    builder.addCase(listSolution.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listSolution.fulfilled, (state, action) => {
      state.solution = action.payload;
      state.loading = false;
    });
    builder.addCase(listSolution.rejected, (state) => {
      state.loading = false;
    });
    //televison_devides
    builder.addCase(listTeleDevi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listTeleDevi.fulfilled, (state, action) => {
      state.tele_devi = action.payload;
      state.loading = false;
    });
    builder.addCase(listTeleDevi.rejected, (state) => {
      state.loading = false;
    });
    //televison
    builder.addCase(listTelevison.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listTelevison.fulfilled, (state, action) => {
      state.televisons = action.payload;
      state.loading = false;
    });
    builder.addCase(listTelevison.rejected, (state) => {
      state.loading = false;
    });
    //viettel_sign
    builder.addCase(listViettelSign.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listViettelSign.fulfilled, (state, action) => {
      state.viettel_sign = action.payload;
      state.loading = false;
    });
    builder.addCase(listViettelSign.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setSearch } = ListProductsSlices.actions;

export default ListProductsSlices.reducer;
