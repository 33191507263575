import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../api/BaseRequest";

export const listBanner = createAsyncThunk("banner", async () => {
  const { listBanners } = await get("banner");
  return listBanners;
});

export const listBannerId = createAsyncThunk("banner/:id", async (id) => {
  const { banner } = await get(`banner/${id}`);
  return banner;
});

const initialState = {
  loading: false,
  listBanners: [],
  bannerId: {},
};

const ListBannerSlices = createSlice({
  name: "listBanner",
  initialState,
  reducers: {
    setLoadingBanner: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    //listbanner
    builder.addCase(listBanner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listBanner.fulfilled, (state, action) => {
      state.listBanners = action.payload;
      state.loading = false;
    });
    builder.addCase(listBanner.rejected, (state) => {
      state.loading = false;
    });
    //bannerId
    builder.addCase(listBannerId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listBannerId.fulfilled, (state, action) => {
      state.bannerId = action.payload;
      state.loading = false;
    });
    builder.addCase(listBannerId.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setLoadingBanner } = ListBannerSlices.actions;

export default ListBannerSlices.reducer;
