import {
  Col,
  Form,
  Input,
  Row,
  Typography,
  Upload,
  Button,
  Spin,
  Space,
  notification,
  InputNumber,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { patch } from "../../../api/BaseRequest";
import { storage } from "../../../utils/firebase";
import "./add.css";
import {
  setLoadingProduct,
  getProductId,
} from "../../../utils/redux/slices/ProductSlice";
import { Editor } from "@tinymce/tinymce-react";
import { apiKey, initCreateProduct } from "../../../helper/dataPostManagement";

const EditProduct = () => {
  //redux
  const dispatch = useDispatch();
  const { productId, loading } = useSelector((state) => state.listProducts);

  //state
  const { link, id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState();
  const [fileSource, setFileSource] = useState();
  const [avatarUrl, setAvatarUrl] = useState("");
  const [valueDescription, setValueDescription] = useState();
  const [valueContent, setValueContent] = useState();

  const editDescriptionRef = useRef(null);
  const editContentRef = useRef(null);

  useEffect(() => {
    dispatch(getProductId(`${link}/${id}`));
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({
      alt: productId.alt,
      price: productId.price,
    });
    setValueDescription(productId.desc);
    setValueContent(productId.content);
  }, [productId]);

  const onFinish = (value) => {
    const { typeProduct, ...rest } = value;
    const newData = {
      ...productId,
      ...rest,
      desc: valueDescription,
      content: valueContent,
      price: Number(value.price),
      src: avatarUrl || productId.src,
    };
    patchProductAPI(newData);
  };
  const patchProduct = (data) => patch(`${link}/${id}`, data);

  const { mutate: patchProductAPI, isLoading: isPatchingProduct } = useMutation(
    patchProduct,
    {
      onSuccess: (data) => {
        history.push(`/admin/product/${link}`);
        notification.success({ message: data.message });
      },
      onError: (error) => {
        notification.error({ message: error.response.data.message });
      },
    }
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  useEffect(() => {
    if (!fileSource) return;
    const storageRef = ref(storage, fileSource?.name);
    const uploadAvatar = uploadBytesResumable(storageRef, fileSource);
    dispatch(setLoadingProduct(true));
    uploadAvatar.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadAvatar.snapshot.ref).then((downloadURL) => {
          setAvatarUrl(downloadURL);
          dispatch(setLoadingProduct(false));
        });
      }
    );
  }, [fileSource]);

  const handleChange = (info) => {
    if (info.file.originFileObj.size / 1048576 <= 10) {
      setFileSource(info.file.originFileObj);
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
    } else {
    }
  };

  const beforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isLt10M) {
      notification.error({ message: "Ảnh tải lên cần dưới 10MB" });
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({ message: "Bạn cần nhập ảnh trong ô này" });
    }

    return isLt10M, isJpgOrPng;
  };

  const handleCancel = () => {
    history.push(`/admin/product/${link}`);
  };

  return (
    <>
      <Spin tip="Đang tải....." spinning={isPatchingProduct}>
        <Row>
          <Col className="form-wrapper" xl={22}>
            <Col className="create-form-title">
              <Typography.Title
                level={3}
              >{`Chỉnh sửa ${productId.alt}`}</Typography.Title>
            </Col>
            <Col xl={24}>
              <Form
                className="create-form"
                name="create-form"
                form={form}
                onFinish={onFinish}
              >
                <Col>
                  <Form.Item
                    className="create-form-item"
                    name="alt"
                    label="Tên tiêu đề"
                    rules={[
                      {
                        required: true,
                        message: "Cần điền trường này",
                      },
                    ]}
                  >
                    <Input placeholder="Tên tiêu đề..." />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    className="create-form-item"
                    name="price"
                    label="Giá thành"
                    rules={[
                      {
                        required: true,
                        message: "Cần điền trường này",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: 200 }}
                      addonAfter="VNĐ"
                      placeholder="Giá thành....."
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
                <div className="rowClass">
                  <label className="label">
                    <b className="required">* </b> Miêu tả:{" "}
                  </label>

                  <Editor
                    value={valueDescription}
                    apiKey={apiKey}
                    onInit={(evt, editor) =>
                      (editDescriptionRef.current = editor)
                    }
                    onEditorChange={(newValueEditor) =>
                      setValueDescription(newValueEditor)
                    }
                    init={{
                      ...initCreateProduct,
                    }}
                  />
                </div>

                <div className="rowClass">
                  <label className="label">
                    <b className="required">* </b> Nội dung:{" "}
                  </label>

                  <Editor
                    value={valueContent}
                    apiKey={apiKey}
                    onInit={(evt, editor) => (editContentRef.current = editor)}
                    onEditorChange={(newValueEditor) =>
                      setValueContent(newValueEditor)
                    }
                    init={{
                      ...initCreateProduct,
                    }}
                  />
                </div>

                <Form.Item className="create-form-item" name="src" label="Ảnh">
                  <Upload
                    name="src"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="http://localhost:3000"
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                  >
                    {imageUrl ? (
                      <Spin spinning={!avatarUrl}>
                        <img
                          src={avatarUrl}
                          alt="avatar"
                          style={{
                            width: "100%",
                          }}
                        />
                      </Spin>
                    ) : (
                      <Spin spinning={!productId.src}>
                        <img
                          src={productId.src}
                          alt="avatar"
                          style={{
                            width: "100%",
                          }}
                        />
                      </Spin>
                    )}
                  </Upload>
                </Form.Item>

                <Col
                  xl={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Space>
                    <Button
                      className="create-form-btn"
                      htmlType="submit"
                      type="primary"
                      loading={loading}
                    >
                      Chỉnh sửa sản phẩm
                    </Button>
                    <Button
                      className="create-form-btn"
                      danger
                      type="primary"
                      onClick={() => handleCancel()}
                    >
                      Hủy
                    </Button>
                  </Space>
                </Col>
              </Form>
            </Col>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default EditProduct;
