import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Input,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "react-query";

import { Editor } from "@tinymce/tinymce-react";
import { apiKey, initCreateProduct } from "../../../helper/dataPostManagement";

import "./custom.css";
import { post } from "../../../api/BaseRequest";
import { customSupportId } from "../../../utils/redux/slices/CustomSlices";
import axios from "axios";

const CreatePageSupport = () => {
  //redux
  const dispatch = useDispatch();
  const { customerId, loading } = useSelector((state) => state.listCustom);

  //state
  const { id } = useParams();
  const [form] = Form.useForm();
  const editorPost = useRef(null);
  const history = useHistory();

  const [valueContent, setValueContent] = useState();

  useEffect(() => {
    if (id) {
      dispatch(customSupportId({ id }));
    }
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({
      titleCode: customerId.titleCode,
      title: customerId.title,
    });
    setValueContent(customerId.content);
  }, [customerId]);

  const onFinish = (value) => {
    const newData = {
      ...value,
      content: valueContent,
    };
    if (valueContent.length > 0) {
      if (id) {
        handleEdit(newData);
      } else {
        createCustom(newData);
      }
    } else {
      notification.error({ message: "Bạn cần nhập bài viết" });
    }
  };

  const postCustom = (data) => post(`customSupport`, data);

  const { mutate: createCustom, isLoading: isPostingProduct } = useMutation(
    postCustom,
    {
      onSuccess: (data) => {
        handleReset();
        notification.success({ message: data.message });
      },
      onError: (error) => {
        console.log(error);
        notification.error({ message: error.response.data.message });
      },
    }
  );

  const handleEdit = (data) =>
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/customSupport`,
        {
          ...data,
        },
        {
          params: {
            id: id,
          },
        }
      )
      .then((res) => {
        notification.success({ message: "Chỉnh sửa thành công" });
        history.push(`/admin/custom-support`);
      })
      .catch((err) => {
        console.log(err);
      });

  const handleReset = () => {
    history.push("/admin/custom-support");
    form.resetFields();
  };

  return (
    <Spin tip="Đang tải dữ liệu...." spinning={isPostingProduct || loading}>
      <Row>
        <Col xxl={20} className="wrapperCustoms">
          <Typography.Title level={3}>
            {id ? "Chỉnh sửa bài viết" : "Tạo bài viết"}
          </Typography.Title>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 2 }}
            initialValues={{
              titleCode: 1,
            }}
          >
            <Form.Item name="titleCode" label="Dịch vụ">
              <Select
                style={{ width: 200 }}
                disabled={id}
                defaultValue={1}
                options={[
                  {
                    value: 1,
                    label: "Câu hỏi thường gặp",
                  },
                  {
                    value: 2,
                    label: "Tìm kiếm cửa hàng",
                  },
                  {
                    value: 3,
                    label: "Phản ảnh, góp ý",
                  },
                  {
                    value: 4,
                    label: "Video hướng dẫn",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="title"
              label="Tiêu đề"
              rules={[
                {
                  required: true,
                  message: "Bạn cần điền trường này",
                },
              ]}
            >
              <Input
                placeholder="Nhập tên tiêu đề"
                className="width60Percent"
              />
            </Form.Item>

            <div className="wrapperContent">
              <label className="label">
                <b className="required">* </b> Nội dung:{" "}
              </label>
              <Editor
                value={valueContent}
                apiKey={apiKey}
                onInit={(evt, editor) => (editorPost.current = editor)}
                onEditorChange={(newValueEditor) =>
                  setValueContent(newValueEditor)
                }
                init={{
                  ...initCreateProduct,
                  height: 400,
                }}
              />
            </div>
            <Row justify="center" style={{ marginTop: 20 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  {id ? "Chỉnh sửa" : "Tạo bài viết"}
                </Button>
                <Button type="primary" danger onClick={handleReset}>
                  Hủy
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default CreatePageSupport;
