import { BsTelephoneFill } from "react-icons/bs";
import logoFooter from "../assets/images/logo-footer.png";
import iconFB from "../assets/images/icon-fb.png";
import iconYT from "../assets/images/icon-yt.png";
import iconZalo from "../assets/images/zalo.png";
import imgZalo from "../assets/images/h-zalo.png";
import { IconContext } from "react-icons";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Space } from "antd";
const Footer = () => {
  return (
    <div className="footer">
      <div className="main-footer footer">
        <div className="footer-content content footer-pc">
          <div className="footer-top">
            <div className="content">
              <div className="footer-inner">
                <div className="column first">
                  <div className="infotop">
                    <div>
                      <div
                        className="logo-fotter"
                        style={{ marginBottom: "50px" }}
                      >
                        <img src={logoFooter} alt="footerLogo" />
                      </div>
                      <h1 className="info">Viettel Hồ Chí Minh</h1>
                      <p style={{ margin: "10px 0" }}>
                        Viettel Tp Hồ Chí Minh cung cấp các dịch vụ: internet
                        cáp quang, truyền hình, các dịch vụ di động và giải pháp
                        dành cho doanh nghiệp
                      </p>
                      <p style={{ margin: "10px 0" }}>
                        Tổng đài hỗ trợ tư vấn và đăng ký: 0963.003.606
                      </p>
                      <p style={{ margin: "10px 0" }}>
                        Tổng đài báo hỏng dịch vụ cố định: 1800 8119 (máy 2)
                      </p>
                      <p style={{ margin: "10px 0" }}>
                        Tổng đài hỗ trợ dịch vụ di động 1800 8098 / 198{" "}
                      </p>
                      <p style={{ margin: "10px 0" }}>
                        Tổng đài hỗ trợ dịch vụ doanh nghiệp 1800 8000
                      </p>
                      <p style={{ margin: "10px 0" }}>
                        Email: vietteltphcm.online@gmail.com
                      </p>
                      <p>Địa chỉ: 285 CMT8, phường 12, quận 10, TpHCM</p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="menu">
                    <h4 style={{ color: "black", textTransform: "uppercase" }}>
                      DỊCH VỤ DOANH NGHIỆP
                    </h4>
                    <ul className="sub-menu">
                      <li>
                        <p>Chữ ký số</p>
                      </li>
                      <li>
                        <p>Hóa đơn điện tử</p>
                      </li>
                      <li>
                        <p>Bảo hiểm xã hội BHXH</p>
                      </li>
                      <li>
                        <p>Vtracking</p>
                      </li>
                      <li>
                        <p>Office WAN</p>
                      </li>
                      <li>
                        <p>Trung Kế Số E1</p>
                      </li>
                      <li>
                        <p>Leasedline Internet</p>
                      </li>
                      <li>
                        <p>Dịch vụ thuê đầu số</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="column">
                  <div className="menu">
                    <h4 style={{ color: "black", textTransform: "uppercase" }}>
                      INTERNET - TRUYỀN HÌNH
                    </h4>
                    <ul className="sub-menu">
                      <li>
                        <p>Internet</p>
                      </li>
                      <li>
                        <p>Truyền hình</p>
                      </li>
                    </ul>
                  </div>
                  <div className="menu">
                    <h4 style={{ color: "black", textTransform: "uppercase" }}>
                      DỊCH VỤ DI ĐỘNG
                    </h4>
                    <ul className="sub-menu">
                      <li>
                        <p>Di động trả sau</p>
                      </li>
                      <li>
                        <p>Di động trả trước</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="column">
                  <div className="menu">
                    <h4 style={{ color: "black", textTransform: "uppercase" }}>
                      HỖ TRỢ KHÁCH HÀNG
                    </h4>
                    <ul className="sub-menu">
                      <li>
                        <p>Tổng đài Viettel</p>
                      </li>
                      <li>
                        <p>Liên hệ</p>
                      </li>
                    </ul>
                  </div>
                  <div className="menu">
                    <h4 style={{ color: "black", textTransform: "uppercase" }}>
                      LIÊN KẾT WEB
                    </h4>
                    <ul className="sub-menu">
                      <li>
                        <p>Tuyển dụng</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="footer-bottom"
            style={{ position: "absolute", right: 0, left: 0 }}
          >
            <div className="content">
              <IconContext.Provider value={{ color: "white", size: "15px" }}>
                <Space size={15}>
                  <FaFacebookF />
                  <FaInstagram />
                  <FaTwitter />
                  <FaYoutube />
                </Space>
              </IconContext.Provider>
            </div>
          </div>
        </div>
        {/* responsive */}
        <div className="content footer-sp" style={{ color: "rgb(0, 0, 0)" }}>
          <div className="infotop">
            {/* <div>
              <div className="logo-fotter">
                <img src={logoFooter} />
              </div>
              <p className="info">
                WEBSITE MUA SẮM ONLINE CHÍNH THỨC CỦA VIETTEL TELECOM.
              </p>
              <p className="info">
                Cơ quan chủ quản: Tổng Công ty Viễn thông Viettel (Viettel
                Telecom) - Chi nhánh Tập đoàn Công nghiệp - Viễn thông Quân đội.
                Mã số doanh nghiệp: 0100109106-011 do Sở Kế hoạch và Đầu tư
                Thành phố Hà Nội cấp lần đầu ngày 18/07/2005, sửa đổi lần thứ 15
                ngày 18/12/2018. Chịu trách nhiệm nội dung: Ông Cao Anh Sơn
              </p>
            </div> */}
            <div className="column first">
              <div className="infotop">
                <div>
                  {/* <div className="logo-fotter" style={{ marginBottom: "50px" }}>
                    <img src={logoFooter} alt="footerLogo" />
                  </div> */}
                  <h1 className="info">Viettel Hồ Chí Minh</h1>
                  <p style={{ margin: "10px 0" }}>
                    Viettel Tp Hồ Chí Minh cung cấp các dịch vụ: internet cáp
                    quang, truyền hình, các dịch vụ di động và giải pháp dành
                    cho doanh nghiệp
                  </p>
                  <p style={{ margin: "10px 0" }}>
                    Tổng đài hỗ trợ tư vấn và đăng ký: 0963.003.606
                  </p>
                  <p style={{ margin: "10px 0" }}>
                    Tổng đài báo hỏng dịch vụ cố định: 1800 8119 (máy 2)
                  </p>
                  <p style={{ margin: "10px 0" }}>
                    Tổng đài hỗ trợ dịch vụ di động 1800 8098 / 198{" "}
                  </p>
                  <p style={{ margin: "10px 0" }}>
                    Tổng đài hỗ trợ dịch vụ doanh nghiệp 1800 8000
                  </p>
                  <p style={{ margin: "10px 0" }}>
                    Email: vietteltphcm.online@gmail.com
                  </p>
                  <p style={{ marginBottom: "12px" }}>
                    Địa chỉ: 285 CMT8, phường 12, quận 10, TpHCM
                  </p>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="menu">
                <h4
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                >
                  DỊCH VỤ DOANH NGHIỆP
                </h4>
                <ul className="sub-menu">
                  <li>
                    <p>Chữ ký số</p>
                  </li>
                  <li>
                    <p>Hóa đơn điện tử</p>
                  </li>
                  <li>
                    <p>Bảo hiểm xã hội BHXH</p>
                  </li>
                  <li>
                    <p>Vtracking</p>
                  </li>
                  <li>
                    <p>Office WAN</p>
                  </li>
                  <li>
                    <p>Trung Kế Số E1</p>
                  </li>
                  <li>
                    <p>Leasedline Internet</p>
                  </li>
                  <li>
                    <p>Dịch vụ thuê đầu số</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="column">
              <div className="menu">
                <h4
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                >
                  INTERNET - TRUYỀN HÌNH
                </h4>
                <ul className="sub-menu">
                  <li>
                    <p>Internet</p>
                  </li>
                  <li>
                    <p>Truyền hình</p>
                  </li>
                </ul>
              </div>
              <div className="menu">
                <h4
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                >
                  DỊCH VỤ DI ĐỘNG
                </h4>
                <ul className="sub-menu">
                  <li>
                    <p>Di động trả sau</p>
                  </li>
                  <li>
                    <p>Di động trả trước</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="column">
              <div className="menu">
                <h4
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                >
                  HỖ TRỢ KHÁCH HÀNG
                </h4>
                <ul className="sub-menu">
                  <li>
                    <p>Tổng đài Viettel</p>
                  </li>
                  <li>
                    <p>Liên hệ</p>
                  </li>
                </ul>
              </div>
              <div className="menu">
                <h4
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                >
                  LIÊN KẾT WEB
                </h4>
                <ul className="sub-menu">
                  <li>
                    <p>Tuyển dụng</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="infobellow">
            <ul className="link-social">
              <li>
                <img src={iconFB} alt="iconFb" />
              </li>
              <li>
                <img src={iconYT} alt="iconYt" />
              </li>
              <li>
                <img src={iconZalo} alt="iconZalo" />
              </li>
            </ul>
            <p>© Viettel Telecom 2015. All rights reserved.</p>
          </div>
          <div className="wrapsitemap">Sitesmap</div>
        </div>
      </div>
      <a href="tel:0963003606">
        <div className="phone-main">
          <div className="phone">
            <span>
              <BsTelephoneFill className="icon-phone" />
            </span>
          </div>
          <div className="circle1"></div>
          <div className="circle2"></div>
        </div>
      </a>
      <a href="https://zalo.me/0963003606">
        <div className="zalo-main">
          <div className="phone">
            <span>
              <img src={imgZalo} alt="iconFb" style={{ width: "16px" }} />
            </span>
          </div>
          <div className="circle1"></div>
          <div className="circle2"></div>
        </div>
      </a>
    </div>
  );
};

export default Footer;
