import React from "react";
import { Modal, Button, Result, Statistic, Typography } from "antd";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const { Countdown } = Statistic;

const ModalConfirm = ({ isModalConfirm, setIsModalConfirm }) => {
  const history = useHistory();

  const onFinish = () => {
    history.push("/");
    setIsModalConfirm(false);
  };

  const onChangePage = () => {
    history.push("/");
  };

  return (
    <Modal visible={isModalConfirm} footer={null}>
      <Result
        status="success"
        title="Cảm ơn bạn đã sử dụng dịch vụ của Viettel"
        subTitle="Thông tin của bạn đã được admin ghi nhận và sẽ liên hệ sớm nhất đến bạn."
        extra={[
          <>
            <Countdown value={Date.now() + 5 * 1000} onFinish={onFinish} />
            <div className="groupButtonModal">
              <Text>Đếm ngược để trở lại màn hình chính</Text>
              <Button
                type="primary"
                className="btnConfirm"
                onClick={() => onChangePage()}
              >
                Trở về màn hình chính
              </Button>
            </div>
          </>,
        ]}
      />
    </Modal>
  );
};

export default ModalConfirm;
