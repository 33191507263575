import React, { useEffect } from "react";
import { Col, Divider, Empty, Spin } from "antd";

import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { customSupportClient } from "../../../utils/redux/slices/CustomSlices";
import { useParams } from "react-router-dom";
import { titleNameCustomClient, urlConvertCode } from "../../../utils";

const CustomSupportClient = () => {
  //redux
  const dispatch = useDispatch();
  const { customerClient, loading } = useSelector((state) => state.listCustom);

  const { id } = useParams();

  useEffect(() => {
    dispatch(customSupportClient({ titleCode: urlConvertCode(id) }));
  }, [id]);

  return (
    <Col>
      <div className="wrapperQuestion">
        <h1>{titleNameCustomClient(id)}</h1>
        <Divider />
        <Spin tip="Đang tải...." spinning={loading}>
          {customSupportClient ? (
            <div
              className="wrapperContentCustom"
              dangerouslySetInnerHTML={{ __html: customerClient.content }}
            />
          ) : (
            <div className="wrapperContent">
              <Empty description={false} />
            </div>
          )}
        </Spin>
      </div>
    </Col>
  );
};

export default CustomSupportClient;
