import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../api/BaseRequest";

export const listContacts = createAsyncThunk("contact", async () => {
  const { dataContact } = await get("contact");
  return dataContact;
});

const initialState = {
  loading: false,
  listContact: [],
};

const ListContactSlices = createSlice({
  name: "listContact",
  initialState,
  extraReducers: (builder) => {
    //listbanner
    builder.addCase(listContacts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listContacts.fulfilled, (state, action) => {
      state.listContact = action.payload;
      state.loading = false;
    });
    builder.addCase(listContacts.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ListContactSlices.reducer;
