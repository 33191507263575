import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";

import HeaderHome from "../../components/Header";
import FooterHome from "../../components/Footer";
import Head from "./header/header";
import SideBar from "./sider/sider";
import "./AdminLayout.css";

const { Sider, Header, Content } = Layout;
const AdminLayout = (props) => {
  const location = useLocation();
  const { renderRouter } = props;
  const [collapsed, setCollapsed] = useState(false);

  const IS_LOGIN = location?.pathname?.includes("/login");
  const IS_ADMIN = location?.pathname?.includes("/admin");

  return (
    <>
      {IS_ADMIN ? (
        <Layout>
          <Header className="header-admin">
            <Head />
          </Header>
          <Layout>
            <Sider
              className="sider-admin"
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
            >
              <SideBar />
            </Sider>
            <Content className="content-admin">{renderRouter()}</Content>
          </Layout>
        </Layout>
      ) : (
        <>
          {IS_LOGIN ? (
            renderRouter()
          ) : (
            <>
              <HeaderHome />
              {renderRouter()}
              <FooterHome />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdminLayout;
