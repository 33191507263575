import React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Typography, Spin, message } from "antd";
import { useMutation } from "react-query";

import { STORAGEKEY, setCookie } from "../../utils/storage";
import { post } from "../../api/BaseRequest";

import "./login.css";

const { Title } = Typography;

const Login = () => {
  const postLogin = (data) => post(`login`, data);

  const { mutate: postLoginAPI, isLoading: isPosttingLoginAPI } = useMutation(
    postLogin,
    {
      onSuccess: (data) => {
        message.success(`${data.message}`);
        setCookie(STORAGEKEY.ACCESS_TOKEN, data.accessToken);
        window.location.href = "/admin/list-phone";
      },
      onError: (error) => {
        message.error(`${error.response.data.message}`);
      },
    }
  );

  const onFinish = (values) => {
    postLoginAPI(values);
  };

  return (
    <div className="wrapperPage">
      <Spin tip="Đang đăng nhập......" spinning={isPosttingLoginAPI}>
        <Form
          name="Login_admin"
          className="formLogin"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Title className="textHeader">
            <span>Đăng nhập</span>
          </Title>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Cần nhập email !",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Nhập email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Cần nhập mật khẩu !",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Nhập mật khẩu"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Ghi nhớ đăng nhập</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="loginFormButton"
            >
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default Login;
