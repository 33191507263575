import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  notification,
  Row,
  Select,
  Spin,
  Button,
  Space,
} from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { post } from "../../../api/BaseRequest";
import {
  apiKey,
  initFullProps,
  optionsCategory,
} from "../../../helper/dataPostManagement";
import { listTitlePost } from "../../../utils/redux/slices/TitlePostSlices";

import "./post.css";

const AddPost = () => {
  //redux
  const dispatch = useDispatch();
  const { listTitle } = useSelector((state) => state.listTitlePost);

  const [form] = Form.useForm();
  //state
  const editorRef = useRef(null);
  const [valueEditor, setValueEditor] = useState("");
  const history = useHistory();

  useEffect(() => {
    dispatch(listTitlePost({ title: 1 }));
  }, []);

  const onFinish = (value) => {
    const newData = {
      ...value,
      content: valueEditor,
    };
    postAPI(newData);
  };

  const handleChange = (value) => {
    dispatch(listTitlePost({ title: value }));
  };

  const postInfoService = (data) => post(`posts`, data);

  const { mutate: postAPI, isLoading: isPostingInfo } = useMutation(
    postInfoService,
    {
      onSuccess: (data) => {
        notification.success({ message: data.message });
        history.push("/admin/posts");
      },
      onError: (error) => {
        notification.error({ message: error.response.data.message });
      },
    }
  );

  return (
    <>
      <Spin tip="Đang tải....." spinning={isPostingInfo}>
        <div className="wrapperPost">
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              category: 1,
            }}
          >
            <Row justify="space-between">
              <Col xl={10} xxl={8}>
                <Form.Item name="category" label="Tên dịch vụ">
                  <Select
                    defaultValue={1}
                    options={optionsCategory}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col xl={10} xxl={8}>
                <Form.Item
                  name="subCategory"
                  label="Nâng cao"
                  rules={[
                    { required: true, message: "Bạn cần chọn trường này" },
                  ]}
                >
                  <Select
                    options={listTitle.map((item) => {
                      return {
                        value: item.valueTitle,
                        label: item.postTitle,
                        disabled: !item.status,
                      };
                    })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ marginBottom: 20 }}>
              <Editor
                id="editor"
                value={valueEditor}
                apiKey={apiKey}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onEditorChange={(newValueEditor) =>
                  setValueEditor(newValueEditor)
                }
                init={{
                  ...initFullProps,
                }}
              />
            </div>

            <div className="wrapperButton">
              <Space>
                <Button type="primary" htmlType="submit">
                  Tạo bài đăng
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => history.push(`/admin/posts`)}
                >
                  Hủy
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default AddPost;
