import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../api/BaseRequest";

export const listProduct = createAsyncThunk("product", async (link) => {
  const { products } = await get(`${link}`);
  return products;
});

export const getProductId = createAsyncThunk("productId", async (link) => {
  const { productId } = await get(`${link}`);
  return productId;
});

const initialState = {
  loading: false,
  listProducts: [],
  productId: {},
};

const ListProductsSlices = createSlice({
  name: "listProduct",
  initialState,
  reducers: {
    setLoadingProduct: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    //listbanner
    builder.addCase(listProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listProduct.fulfilled, (state, action) => {
      state.listProducts = action.payload;
      state.loading = false;
    });
    builder.addCase(listProduct.rejected, (state) => {
      state.loading = false;
    });
    //product id
    builder.addCase(getProductId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductId.fulfilled, (state, action) => {
      state.productId = action.payload;
      state.loading = false;
    });
    builder.addCase(getProductId.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setLoadingProduct } = ListProductsSlices.actions;

export default ListProductsSlices.reducer;
