import { Menu } from "antd";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { subPathname } from "../../../utils";

import {
  PhoneOutlined,
  ContainerOutlined,
  PictureOutlined,
  BarcodeOutlined,
  WifiOutlined,
  MobileOutlined,
  LaptopOutlined,
  DesktopOutlined,
  MenuOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import "./sider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEdit,
  faReceipt,
  faSignature,
} from "@fortawesome/free-solid-svg-icons";

const SideBar = () => {
  //state
  const { pathname } = useLocation();

  const pageCreate = pathname.includes("create");

  const getItem = (label, icon, key, children, type) => {
    return {
      label,
      icon,
      key,
      children,
      type,
    };
  };

  const items = [
    getItem(
      <NavLink to="/admin/list-phone">Danh sách số điện thoại </NavLink>,
      <PhoneOutlined />,
      "/admin/list-phone"
    ),
    getItem(
      <NavLink to="/admin/list-contact">Danh sách liên hệ</NavLink>,
      <ContainerOutlined />,
      "/admin/list-contact"
    ),
    getItem(
      <NavLink to="/admin/banner">Quản lý banner</NavLink>,
      <PictureOutlined />,
      "/admin/banner"
    ),
    getItem("Quản lý sản phẩm", <BarcodeOutlined />, "/admin/product", [
      getItem(
        <NavLink to="/admin/product/internet-wifi">Internet wifi</NavLink>,
        <WifiOutlined />,
        "/admin/product/internet-wifi"
      ),
      getItem(
        <NavLink to="/admin/product/mobile">Di động</NavLink>,
        <MobileOutlined />,
        "/admin/product/mobile"
      ),
      getItem(
        <NavLink to="/admin/product/viettel_sign">Chữ ký số viettel</NavLink>,
        <FontAwesomeIcon icon={faSignature} />,
        "/admin/product/viettel_sign"
      ),
      getItem(
        <NavLink to="/admin/product/e_invoices">Hóa đơn điện tử</NavLink>,
        <FontAwesomeIcon icon={faReceipt} />,
        "/admin/product/e_invoices"
      ),
      getItem(
        <NavLink to="/admin/product/devides">Thiết bị</NavLink>,
        <LaptopOutlined />,
        "/admin/product/devides"
      ),
      getItem(
        <NavLink to="/admin/product/company">Doanh nghiệp</NavLink>,
        <FontAwesomeIcon icon={faBuilding} />,
        "/admin/product/company"
      ),
      getItem(
        <NavLink to="/admin/product/televisons">Truyền hình</NavLink>,
        <DesktopOutlined />,
        "/admin/product/televisons"
      ),
    ]),
    getItem(
      <NavLink to="/admin/posts">Quản lý bài viết</NavLink>,
      <FontAwesomeIcon icon={faEdit} />,
      "/admin/posts"
    ),
    getItem("Quản lý danh mục", <MenuOutlined />, "/admin/title", [
      getItem(
        <NavLink to="/admin/title/internet-wifi">Internet wifi</NavLink>,
        <WifiOutlined />,
        "/admin/title/internet-wifi"
      ),
      getItem(
        <NavLink to="/admin/title/mobile">Di động</NavLink>,
        <MobileOutlined />,
        "/admin/title/mobile"
      ),
      getItem(
        <NavLink to="/admin/title/viettel_sign">Chữ ký số viettel</NavLink>,
        <FontAwesomeIcon icon={faSignature} />,
        "/admin/title/viettel_sign"
      ),
      getItem(
        <NavLink to="/admin/title/e_invoices">Hóa đơn điện tử</NavLink>,
        <FontAwesomeIcon icon={faReceipt} />,
        "/admin/title/e_invoices"
      ),
      getItem(
        <NavLink to="/admin/title/devides">Thiết bị</NavLink>,
        <LaptopOutlined />,
        "/admin/title/devides"
      ),
      getItem(
        <NavLink to="/admin/title/company">Doanh nghiệp</NavLink>,
        <FontAwesomeIcon icon={faBuilding} />,
        "/admin/title/company"
      ),
      getItem(
        <NavLink to="/admin/title/televisons">Truyền hình</NavLink>,
        <DesktopOutlined />,
        "/admin/title/televisons"
      ),
    ]),
    getItem(
      <NavLink to="/admin/custom-support">Quản lý hỗ trợ khách hàng</NavLink>,
      <TeamOutlined />,
      "/admin/custom-support"
    ),
  ];

  return (
    <Menu
      defaultSelectedKeys={["1"]}
      mode="inline"
      items={items}
      defaultOpenKeys={[subPathname(pathname)]}
      selectedKeys={[pageCreate ? subPathname(pathname) : pathname]}
    />
  );
};

export default SideBar;
