import { combineReducers } from "@reduxjs/toolkit";

import ListBannerSlices from "../slices/BannerSlices";
import ProductSlice from "../slices/ProductSlice";
import PostSlice from "../slices/PostSlice";
import ProductDisplaySlice from "../slices/ProductDisplaySlice";
import ListPhoneSlices from "../slices/ListPhoneSlices";
import TitlePostSlices from "../slices/TitlePostSlices";
import ListContactSlices from "../slices/ContactSlices";
import CustomSlices from "../slices/CustomSlices";

export default combineReducers({
  listPhone: ListPhoneSlices,
  listBanner: ListBannerSlices,
  listProducts: ProductSlice,
  listPosts: PostSlice,
  listAllProduct: ProductDisplaySlice,
  listTitlePost: TitlePostSlices,
  listContact: ListContactSlices,
  listCustom: CustomSlices,
});
