import { Button, Col, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { io } from "socket.io-client";
import { Excel } from "antd-table-saveas-excel";

import { listPhone } from "../../../utils/redux/slices/ListPhoneSlices";
import "./index.css";

const headCells2 = [
  {
    title: "Số điện thoại",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Tên dịch vụ",
    dataIndex: "title",
    key: "title",
    render: (val, key) => {
      return <span style={{ textTransform: "uppercase" }}>{val}</span>;
    },
  },
  {
    title: "Gói cước",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Ngày đăng ký",
    dataIndex: "registerDate",
    key: "registerDate",
    render: (val, key) => {
      return <text>{moment(val).format("DD/MM/YYYY HH:mm")}</text>;
    },
  },
];

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
});

const ListPhone = () => {
  //redux
  const { listPhones } = useSelector((state) => state.listPhone);

  //state
  const [pagination, setPagination] = useState({
    pageSize: 15,
    total: listPhones.length,
    pageSizeOptions: [10, 15, 20],
    showSizeChanger: true,
    position: ["topCenter", "bottomCenter"],
  });

  const handleChangeTable = (value) => {
    setPagination({
      ...value,
      pageSize: value.pageSize,
    });
  };

  const exportData = () => {
    const excel = new Excel();
    excel
      .addSheet(`Số_điện_thoại_${moment().format("DD_MMMM_YYYY")}`)
      .addColumns(headCells2)
      .addDataSource(listPhones, {
        str2Percent: true,
      })
      .saveAs(`Số điện thoại_${moment().format("DDMMYYYY")}.xlsx`);
  };

  socket.on("confirm", () => {
    dispatch(listPhone());
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listPhone());
  }, []);

  return (
    <Row>
      <Col className="table-phone" xl={22}>
        <Col className="table-title" xl={24}>
          <div>
            <Typography.Title level={3}>
              Danh sách số điện thoại
            </Typography.Title>
          </div>
          <div className="buttonExport">
            <Button type="primary" onClick={() => exportData()}>
              Export
            </Button>
          </div>
        </Col>
        <Table
          columns={headCells2}
          dataSource={listPhones}
          pagination={pagination}
          onChange={handleChangeTable}
        />
      </Col>
    </Row>
  );
};

export default ListPhone;
