import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../api/BaseRequest";

export const listTitlePost = createAsyncThunk(
  "listTitlePost",
  async (value) => {
    const { listTitlePost: data } = await get(`postTitle`, value);
    return data;
  }
);

export const detailTitlePost = createAsyncThunk(
  "detailTitle",
  async (value) => {
    const { detailTitlePost: data } = await get(`postTitle`, value);
    return data;
  }
);

const initialState = {
  loading: false,
  listTitle: [],
  detailTitle: {},
};

const TitlePostSlices = createSlice({
  name: "titlePost",
  initialState,
  extraReducers: (builder) => {
    //listTitlePost
    builder.addCase(listTitlePost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listTitlePost.fulfilled, (state, action) => {
      state.listTitle = action.payload;
      state.loading = false;
    });
    builder.addCase(listTitlePost.rejected, (state) => {
      state.loading = false;
    });
    //detailTitlePost
    builder.addCase(detailTitlePost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(detailTitlePost.fulfilled, (state, action) => {
      state.detailTitle = action.payload;
      state.loading = false;
    });
    builder.addCase(detailTitlePost.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default TitlePostSlices.reducer;
