import React, { useEffect, useRef, useState } from "react";
import { Col, Form, notification, Row, Select, Spin } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Space, message } from "antd";
import { useMutation } from "@tanstack/react-query";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { patch } from "../../../api/BaseRequest";
import {
  apiKey,
  initFullProps,
  nameCategory,
  optionsCategory,
  subNameData,
} from "../../../helper/dataPostManagement";
import { postIds } from "../../../utils/redux/slices/PostSlice";
import { listTitlePost } from "../../../utils/redux/slices/TitlePostSlices";

import "./post.css";

const EditPost = () => {
  //redux
  const dispatch = useDispatch();
  const { postId } = useSelector((state) => state.listPosts);
  const { listTitle } = useSelector((state) => state.listTitlePost);

  //state
  const { id } = useParams();
  const editorRef = useRef(null);
  const [valueEditor, setValueEditor] = useState("");
  const [form] = Form.useForm();

  const history = useHistory();

  useEffect(() => {
    dispatch(postIds(id));
    dispatch(listTitlePost(postId.category));
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({
      category: nameCategory(postId.category),
      subCategory: subNameData(postId.subCategory, listTitle),
    });
    setValueEditor(postId.content);
  }, [postId]);

  const onFinish = () => {
    const newData = {
      ...postId,
      content: valueEditor,
    };
    patchApi(newData);
  };

  const patchInfoService = (data) => patch(`posts/${id}`, data);

  const { mutate: patchApi, isLoading: isPatchingInfo } = useMutation(
    patchInfoService,
    {
      onSuccess: (data) => {
        notification.success({ message: data.message });
        history.push("/admin/posts");
      },
      onError: (error) => {
        notification.error({ message: error.response.data.message });
      },
    }
  );

  return (
    <>
      <Spin tip="Đang tải....." spinning={isPatchingInfo}>
        <div className="wrapperPost">
          <Form form={form} onFinish={onFinish}>
            <Row justify="space-between">
              <Col xl={10} xxl={8}>
                <Form.Item name="category" label="Tên dịch vụ">
                  <Select defaultValue={1} options={optionsCategory} disabled />
                </Form.Item>
              </Col>
              <Col xl={10} xxl={8}>
                <Form.Item
                  name="subCategory"
                  label="Nâng cao"
                  rules={[
                    { required: true, message: "Bạn cần chọn trường này" },
                  ]}
                >
                  <Select disabled />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ marginBottom: 20 }}>
              <Editor
                id="editor"
                value={valueEditor}
                apiKey={apiKey}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onEditorChange={(newValueEditor) =>
                  setValueEditor(newValueEditor)
                }
                init={{
                  ...initFullProps,
                }}
              />
            </div>

            <div className="wrapperButton">
              <Space>
                <Button type="primary" htmlType="submit">
                  Chỉnh sửa bài đăng
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => history.push(`/admin/posts`)}
                >
                  Hủy
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default EditPost;
