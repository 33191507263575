import React, { useEffect } from "react";
import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { paginationTable, titleNameCustom } from "../../../utils";
import { customSupport } from "../../../utils/redux/slices/CustomSlices";

import "./custom.css";

const CustomSupport = () => {
  const column = [
    {
      title: "STT",
      dataIndex: "index",
      render: (d, _, index) => <span>{index + 1}</span>,
    },
    {
      title: "Tên dịch vụ",
      dataIndex: "titleCode",
      render: (data) => <span>{titleNameCustom(data)}</span>,
    },
    {
      title: "Tên tiêu đề",
      dataIndex: "title",
    },
    {
      title: "Nội dung tóm tắt",
      dataIndex: "content",
      render: (data) => (
        <div
          style={{ maxHeight: 300, overflowY: "scroll" }}
          dangerouslySetInnerHTML={{ __html: data }}
        />
      ),
    },
    {
      title: "Action",
      render: (key) => (
        <Space>
          <Button
            type="primary"
            onClick={() => history.push(`/admin/custom-support/${key._id}`)}
          >
            Chỉnh sửa
          </Button>
          <Popconfirm
            title="Bạn có chắc chắn muốn xóa bài viết không ?"
            okText="Chắc chắn"
            cancelText="Hủy"
            onConfirm={() => handleDelete(key)}
          >
            <Button type="primary" danger>
              Xóa
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  //redux
  const dispatch = useDispatch();
  const { listCustomer, loading } = useSelector((state) => state.listCustom);
  const history = useHistory();

  useEffect(() => {
    dispatch(customSupport());
  }, []);

  const handleDelete = (value) =>
    axios
      .delete(`${process.env.REACT_APP_API_URL}/customSupport`, {
        params: {
          id: value._id,
        },
      })
      .then(() => {
        notification.success({ message: "Xóa tiêu đề thành công" });
        dispatch(customSupport());
      })
      .catch(() => {
        notification.error({ message: "Tiêu đề không tồn tại" });
      });

  return (
    <Row>
      <Col xxl={20} className="wrapperCustoms">
        <Typography.Title level={3}>Quản lý bài viết</Typography.Title>
        <Row className="rowContent">
          <Button
            type="primary"
            onClick={() => history.push("/admin/custom-support/create")}
          >
            Tạo mới
          </Button>
        </Row>
        <Table
          bordered
          columns={column}
          dataSource={listCustomer}
          loading={loading}
          pagination={paginationTable}
        />
      </Col>
    </Row>
  );
};

export default CustomSupport;
