import React, { useState } from "react";
import { Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

import { setSearch } from "../utils/redux/slices/ProductDisplaySlice";

import {
  faHouse,
  faCircleInfo,
  faNewspaper,
  faLink,
  faGlobe,
  faTv,
  faSimCard,
  faSignature,
  faReceipt,
  faLaptopFile,
  faBuilding,
  faPhone,
  faBars,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/logo.png";

const Header = () => {
  //redux
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onChange = () => {
    dispatch(setSearch(placement));
  };

  return (
    <header className="header js-header header--sticky">
      <div id="row-top" className="row-top">
        <div className="content">
          <a href="#" className="off-canvas-toggle">
            <i className="fs-sp-menu"></i>
          </a>
          <div className="blogo">
            <a href="/" className="logo">
              <img src={logo} alt="logo viettel" />
            </a>
          </div>
          <ul className="link-top">
            <li>
              <FontAwesomeIcon icon={faHouse} color="#EE0033" />
              <a href="/">Trang chủ</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faCircleInfo} color="#EE0033" />
              <a href="/gioi-thieu">Giới thiệu</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faNewspaper} color="#EE0033" />
              <a href="/">Tin tức sự kiện</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faLink} color="#EE0033" />
              <a href="/">Liên kết</a>
            </li>
          </ul>
          <div className="wrapperInputSearch">
            <FontAwesomeIcon
              icon={faSearch}
              color="#EE0033"
              className="iconSearch"
              onClick={() => onChange()}
            />
            <input
              value={placement}
              onChange={(e) => setPlacement(e.target.value)}
              className="inputSearch"
              placeholder="Tìm kiếm sản phẩm"
            />
          </div>

          <a href="https://viettel.vn/dang-nhap" className="status-user">
            <i className="icon-user"></i>
          </a>
          <div className="search-top visible-xs">
            <div className="search-sp">
              <div className="search-sp__icon">
                <i className="icon-search icon-i-search"></i>
              </div>
              <div className="search-sp__result">
                <div className="search-sp__inner">
                  <div className="search-sp__box">
                    <div className="search-sp__input">
                      <input
                        id="input_search_mobile"
                        type="text"
                        placeholder="Nhập từ khóa"
                      />
                      <button
                        id="button_search_mobile"
                        className="btn-search-key-word"
                      >
                        <i className="icon-i-search"></i>
                      </button>
                    </div>
                    <ul className="search-sp__tag tag-search">
                      <li id="tag-search_mobile" className="tag-search__item">
                        <a className="tag-search__link">ST15K</a>
                        <a className="tag-search__link">ST30K</a>
                        <a className="tag-search__link">ST120K</a>
                      </li>
                    </ul>
                  </div>
                  <div className="search-sp__box">
                    <p className="search-sp__subtitle">Lịch sử tìm kiếm</p>
                    <ul className="search-sp__history history-search">
                      <li>
                        <a
                          id="list-search_mobile"
                          className="history-search__link"
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <div className="search-sp__box">
                    <div className="search-sp__button">
                      <a
                        id="delete_history_search_mobile"
                        href="#"
                        className="btn btn-large"
                      >
                        Xóa lịch sử tìm kiếm
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row-menu bacgroud-header">
        <div className="menu-pc">
          <div
            className="boxmenu left"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ul className="itemService">
              <li>
                <FontAwesomeIcon icon={faGlobe} color="#fff" />
                <a href="/service/internet-viettel">Internet wifi</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faTv} color="#fff" />
                <a
                  rel="noopener noreferrer"
                  href="/service/truyen-hinh-viettel"
                >
                  Truyền hình
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={faSimCard} color="#fff" />
                <a href="/service/di-dong-viettel">Di động</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faSignature} color="#fff" />
                <a href="/service/chu-ky-so-viettel">Chữ ký số</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faReceipt} color="#fff" />
                <a href="/service/hoa-don-dien-tu-viettel">Hóa đơn điện tử</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faLaptopFile} color="#fff" />
                <a href="/service/thiet-bi-dien-thoai" b-toast-login="">
                  Thiết bị
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={faBuilding} color="#fff" />
                <a href="/service/giai-phap-doanh-nghiep">Doanh nghiệp</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} color="#fff" />
                <a href="/lien-he">Liên hệ</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="header-rs-mobile">
        <FontAwesomeIcon
          icon={faBars}
          color="#EE0033"
          size="2x"
          onClick={showDrawer}
        />
        <Drawer placement="left" onClose={onClose} visible={visible}>
          <a href="/">
            <p className="header-rs-link">Trang chủ</p>
          </a>
          <a href="/gioi-thieu">
            <p className="header-rs-link">Giới thiệu</p>
          </a>
          <a href="/">
            <p className="header-rs-link">Tin tức sự kiện</p>
          </a>
          <a href="/">
            <p className="header-rs-link">Liên kết</p>
          </a>
          <a href="/service/internet-viettel">
            <p className="header-rs-link">Internet wifi</p>
          </a>
          <a href="/service/truyen-hinh-viettel">
            <p className="header-rs-link">Truyền hình</p>
          </a>
          <a href="/service/di-dong-viettel">
            <p className="header-rs-link">Di động</p>
          </a>
          <a href="/service/chu-ky-so-viettel">
            <p className="header-rs-link">Chữ ký số</p>
          </a>
          <a href="/service/hoa-don-dien-tu-viettel">
            <p className="header-rs-link">Hóa đơn điện tử</p>
          </a>
          <a href="/service/thiet-bi-dien-thoai">
            <p className="header-rs-link">Thiết bị</p>
          </a>
          <a href="/service/giai-phap-doanh-nghiep">
            <p className="header-rs-link">Doanh nghiệp</p>
          </a>
          <a href="/lien-he">
            <p className="header-rs-link">Liên hệ</p>
          </a>
        </Drawer>
        <a href="/" className="">
          <img src={logo} alt="logo viettel" />
        </a>
      </div>
    </header>
  );
};

export default Header;
