import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../api/BaseRequest";

export const customSupport = createAsyncThunk("custom", async () => {
  const { data } = await get("customSupport");
  return data;
});

export const customSupportId = createAsyncThunk("customId", async (id) => {
  const { data } = await get("customSupport", id);
  return data;
});

export const customSupportClient = createAsyncThunk(
  "customClient",
  async (id) => {
    const { data } = await get("customSupport", id);
    return data;
  }
);

const initialState = {
  loading: false,
  listCustomer: [],
  customerId: {},
  customerClient: {},
};

const CustomSlices = createSlice({
  name: "Custom_support",
  initialState,
  extraReducers: (builder) => {
    //custom support
    builder.addCase(customSupport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customSupport.fulfilled, (state, action) => {
      state.listCustomer = action.payload;
      state.loading = false;
    });
    builder.addCase(customSupport.rejected, (state) => {
      state.loading = false;
    });
    //custom support id
    builder.addCase(customSupportId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customSupportId.fulfilled, (state, action) => {
      state.customerId = action.payload;
      state.loading = false;
    });
    builder.addCase(customSupportId.rejected, (state) => {
      state.loading = false;
    });
    //custom support client
    builder.addCase(customSupportClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customSupportClient.fulfilled, (state, action) => {
      state.customerClient = action.payload;
      state.loading = false;
    });
    builder.addCase(customSupportClient.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default CustomSlices.reducer;
