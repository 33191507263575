import { Result, Button } from "antd";
import React from "react";

const PageNotFound = () => {
  const onReturnPage = () => {
    window.location.href = "/";
  };
  return (
    <Result
      style={{ marginTop: 100 }}
      status="404"
      title="404"
      subTitle="Xin lỗi, trang web này không tồn tại!"
      extra={
        <Button type="primary" onClick={onReturnPage}>
          Trở về trang chủ
        </Button>
      }
    />
  );
};

export default PageNotFound;
