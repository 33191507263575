import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Dropdown, Avatar, Image, Badge, Empty } from "antd";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";

import "./header.scss";
import logo from "../../../assets/images/logo.png";
import { removeCookie, STORAGEKEY } from "../../../utils/storage";
import {
  listNotify,
  reload,
} from "../../../utils/redux/slices/ListPhoneSlices";
import moment from "moment";

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
});

const Head = () => {
  //redux
  const dispatch = useDispatch();
  const { infoNotify } = useSelector((state) => state.listPhone);

  const [countNotify, setCountNotify] = useState(infoNotify.notify);

  useEffect(() => {
    dispatch(listNotify());
  }, [dispatch]);

  const handleLogout = async () => {
    await removeCookie(STORAGEKEY.ACCESS_TOKEN);
    window.location.href = "/login";
  };

  const handleNotify = (e) => {
    e.preventDefault();
    dispatch(reload());
  };

  socket.on("confirm", () => {
    setCountNotify(countNotify + 1);
    dispatch(listNotify());
  });

  const menuUser = (
    <Menu
      items={[
        {
          key: "1",
          icon: <KeyOutlined />,
          label: <a onClick={() => handleLogout()}>Logout</a>,
        },
      ]}
    />
  );
  const menuNotify = (
    <Menu
      selectable
      items={infoNotify.listNotify?.map((item, index) => {
        return {
          key: index,
          label: (
            <text>
              Số điện thoại <b>{item.phone}</b> vừa đăng ký{" "}
              <span style={{ textTransform: "uppercase" }}>{item.title}</span>{" "}
              gói <b>{item.name}</b> vào ngày{" "}
              {moment(item.registerDate).format("DD/MM/YYYY")}
            </text>
          ),
        };
      })}
    ></Menu>
  );

  const menuEmpty = (
    <div className="menuEmpty">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );

  return (
    <>
      <div className="logo">
        <a href="/admin/list-phone">
          <Image width={120} preview={false} src={logo} />
        </a>
      </div>
      <div className="headerRight">
        <Dropdown
          overlay={infoNotify.listNotify.length > 0 ? menuNotify : menuEmpty}
          trigger={["click"]}
          className="notify"
        >
          <Badge
            count={infoNotify.notify}
            style={{
              backgroundColor: "#1890ff",
              color: "white",
            }}
          >
            <FontAwesomeIcon
              className="notification-icon"
              icon={faBell}
              onClick={(e) => handleNotify(e)}
            />
          </Badge>
        </Dropdown>
        <Dropdown overlay={menuUser} placement="bottomLeft" arrow>
          <Avatar className="avatar" size={36} icon={<UserOutlined />} />
        </Dropdown>
      </div>
    </>
  );
};

export default Head;
