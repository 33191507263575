import React from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "antd/dist/reset.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/animate.css";

import "./assets/css/jquery.modal.min.css";
import "./assets/css/tab.css";
import "./assets/css/styles.css";
import "./assets/css/common-footer/style-common.css";
import "./assets/css/home/search-home-mobile.css";
import "./assets/css/home/styles.css";

import "./assets/css/styles-v2.css";
import "./assets/css/style-5.css";
import "./assets/css/custom.css";
import "./assets/css/display.css";
import "./assets/css/ssv1/fix-async.css";
import "./assets/css/ssv1/custom-menu.css";
import AdminLayout from "./layouts/adminLayout/AdminLayout";
import routes from "./routers";

const queryClient = new QueryClient();
function App() {
  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AdminLayout renderRouter={routes} />
        </Router>
      </QueryClientProvider>
    </CookiesProvider>
  );
}

export default App;
