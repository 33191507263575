import { Col } from "antd";
import React from "react";
import "./style.css";

const Introduction = () => {
  return (
    <Col xxl={24} xs={24}>
      <div className="wrapperIntro">
        <h1>Giới thiệu</h1>
        <div>
          <p>
            Viettel Hồ Chí Minh xin kính gửi tới quý khách hàng lời chào trân
            trọng và lời cảm ơn sâu sắc vì đã quan tâm, ủng hộ đối với Viettel
            trong suốt thời gian qua !
          </p>
        </div>
        <p>
          Viettel chính thức gia nhập thị trường viễn thông từ ngày 15/10/2000,
          cho đến nay Tổng Công ty Viễn thông Viettel (Viettel Telecom) thuộc
          Tập đoàn công nghiệp Viễn thông Quân đội Viettel đã trở thành doanh
          nghiệp viễn thông hàng đầu tại Việt Nam.
        </p>
        <p>
          Viettel hiện đang cung cấp rất nhiều sản phẩm, dịch vụ viễn thông, sản
          phẩm ứng dụng công nghệ thông tin chất lượng cao. Với mong muốn cung
          cấp tới quý khách hàng những dịch vụ hoàn hảo, mang lại hiệu quả cao
          trong công việc.
        </p>
        <p>Trân trọng cảm ơn sự quan tâm của quý khách !</p>
        <div className="wrapperImg">
          <img
            src="https://khs247.com/wp-content/uploads/2021/05/Busway-du-an-toa-nha-Viettel-Building-B.jpg"
            alt="Toa-nha-viettel-hcm"
          />
          <p className="titleImg">Tòa nhà Viettel tại TpHCM</p>
        </div>
        <p>
          Viettel Thành Phố Hồ Chí Mính - Chi Nhánh Tập Đoàn Viễn Thông Quân
          Đội.
        </p>
        <p>
          - Địa chỉ: 285 Cách Mạng Tháng 8, Phường 12, Quận 10, Thành Phố Hồ Chí
          Minh.
        </p>
        <p>- Điện thoại: 0963.003.606</p>
        <p>
          - Website:{" "}
          <a href="https://vietteltphcm.online" alt="linkWebViettel">
            https://vietteltphcm.online
          </a>
        </p>
      </div>
    </Col>
  );
};

export default Introduction;
