import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD5NTkuIKCVqAKRLTmwD3Vv9-0ExdaIu5s",
  authDomain: "viettel-firebase-11f17.firebaseapp.com",
  projectId: "viettel-firebase-11f17",
  storageBucket: "viettel-firebase-11f17.appspot.com",
  messagingSenderId: "184189990344",
  appId: "1:184189990344:web:10ac2c0f4fcf838fba7cfd",
  measurementId: "G-3JH37V3LK7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
