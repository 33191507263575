import {
  Row,
  Col,
  Typography,
  Button,
  Space,
  Table,
  Modal,
  message,
  Spin,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";

import { del } from "../../../api/BaseRequest";
import { listBanner } from "../../../utils/redux/slices/BannerSlices";
import "./banner.css";
import { paginationTable } from "../../../utils";

const { confirm } = Modal;

const ListBanner = () => {
  //columns table
  const headCells1 = [
    {
      title: "STT",
      dataIndex: "index",
      render: (d, _, index) => <span>{index + 1}</span>,
      width: 80,
    },
    {
      title: "Tên banner",
      dataIndex: "alt",
      key: "alt",
    },
    {
      title: "Banner",
      dataIndex: "src",
      key: "src",
      render: (val, key) => {
        return <img src={val} style={{ width: 150, height: 50 }} />;
      },
    },
    {
      title: "Link banner",
      dataIndex: "src",
      key: "src",
      ellipsis: true,
    },
    {
      title: "Link url banner",
      dataIndex: "url",
      key: "url",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "_id",
      render: (val, key) => (
        <>
          <Space>
            <Button type="primary" onClick={() => editBanner(key._id)}>
              Chỉnh sửa
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(key)}
            >
              Xóa
            </Button>
          </Space>
        </>
      ),
    },
  ];

  //state
  const history = useHistory();

  //redux
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listBanner());
  }, []);

  const data = useSelector((state) => state.listBanner);

  //Modal
  const showDeleteConfirm = (key) => {
    confirm({
      title: "Bạn có muốn xóa thông tin ?",
      icon: <ExclamationCircleOutlined />,
      content: key.alt,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        delBannerAPI(key._id);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //Updaload data
  const delBanner = (id) => del(`banner/${id}`);

  const { mutate: delBannerAPI, isLoading: isDeletingBanner } = useMutation(
    delBanner,
    {
      onSuccess: (data) => {
        dispatch(listBanner());
        message.success(`${data.message}`);
      },
      onError: (error) => {
        message.error(`${error.response.data.message}`);
      },
    }
  );

  //history push id
  const editBanner = (id) => {
    history.push(`/admin/banner/${id}`);
  };

  return (
    <Spin spinning={isDeletingBanner}>
      <Row>
        <Col className="table-product" xl={20}>
          <Col className="table-titles" xl={22}>
            <Typography.Title level={3}>Quản lý banner</Typography.Title>
            <Button type="primary">
              <Link to="/admin/banner/create">Tạo mới</Link>
            </Button>
          </Col>
          <Table
            bordered
            columns={headCells1}
            dataSource={data.listBanners}
            loading={data.loading}
            pagination={paginationTable}
          />
        </Col>
      </Row>
    </Spin>
  );
};
export default ListBanner;
