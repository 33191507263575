import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/login/Login";
import ResetPassword from "./pages/home/ResetPassword";
import Home from "./pages/home/Home";
import { getCookie, STORAGEKEY } from "./utils/storage";

import PageNotFound from "./pages/404";
import ListBanner from "./pages/admin/banner";
import AddBanner from "./pages/admin/banner/add";
import ListPost from "./pages/admin/postManagement";
import AddProduct from "./pages/admin/productManagement/add";
import AddPost from "./pages/admin/postManagement/add";
import Detail from "./pages/home/detail/Detail";
import EditBanner from "./pages/admin/banner/edit";
import productManagement from "./pages/admin/productManagement";
import ListPhone from "./pages/admin/listPhone";
import EditProduct from "./pages/admin/productManagement/edit";
import EditPost from "./pages/admin/postManagement/edit";
import Introduction from "./pages/home/Introduction/Introduction";
import Contract from "./pages/home/contract/Contract";
import TitleManagement from "./pages/admin/titleManagement";
import CreateTitle from "./pages/admin/titleManagement/add";
import ListContact from "./pages/admin/contact";
import CustomSupport from "./pages/admin/customSupport";
import CreatePageSupport from "./pages/admin/customSupport/add";
import CustomSupportClient from "./pages/home/customSupport";

export const appRouter = [
  {
    name: "Introduction",
    path: "/gioi-thieu",
    component: Introduction,
    meta: {
      role: "*",
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },
  {
    name: "Contact",
    path: "/lien-he",
    component: Contract,
    meta: {
      role: "*",
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },
  {
    name: "CustomQuestion",
    path: "/custom/:id",
    component: CustomSupportClient,
    meta: {
      role: "*",
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    meta: {
      role: "*",
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },
  {
    name: "Reset Password",
    path: "/admin/reset-password",
    component: ResetPassword,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "Admin",
    path: "/admin",
    component: ListPhone,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },

  {
    name: "Admin",
    path: "/admin/list-contact",
    component: ListContact,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "List Phone",
    path: "/admin/list-phone",
    component: ListPhone,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "Banner",
    path: "/admin/banner",
    component: ListBanner,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "BannerCreate",
    path: "/admin/banner/create",
    component: AddBanner,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "BannerDetail",
    path: "/admin/banner/:id",
    component: EditBanner,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "ProductManagement",
    path: "/admin/product/:link",
    component: productManagement,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "ProductCreate",
    path: "/admin/product/:link/create",
    component: AddProduct,
    meta: {
      role: "*",
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "ProductCreate",
    path: "/admin/product/:link/:id",
    component: EditProduct,
    meta: {
      role: "*",
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "Posts",
    path: "/admin/posts",
    component: ListPost,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "PostCreate",
    path: "/admin/posts/create",
    component: AddPost,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "PostDetail",
    path: "/admin/posts/:id",
    component: EditPost,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "TitleManagement",
    path: "/admin/title/:id",
    component: TitleManagement,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "TitleCreate",
    path: "/admin/title/:id/create",
    component: CreateTitle,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "TitleEdit",
    path: "/admin/title/:id/edit/:idPost",
    component: CreateTitle,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "CustomSupport",
    path: "/admin/custom-support",
    component: CustomSupport,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "CustomSupport",
    path: "/admin/custom-support/create",
    component: CreatePageSupport,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "CustomSupport",
    path: "/admin/custom-support/:id",
    component: CreatePageSupport,
    meta: {
      role: ["admin"],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: "Detail",
    path: "/service/:urlDetail",
    component: Detail,
    meta: {
      role: "*",
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },
];

const whiteList = [
  "/login",
  "/service/:urlDetail",
  "/gioi-thieu",
  "/lien-he",
  "/custom/:id",
];

const PrivateRoute = (props) => {
  return (
    <Route
      path={props.path}
      exact
      render={(prop) =>
        getCookie(STORAGEKEY.ACCESS_TOKEN) ? (
          <>{React.createElement(props.component, prop)}</>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { redirect_url: prop.location },
            }}
          />
        )
      }
    />
  );
};

const WhiteListRoute = (props) => {
  const isWhiteList = (path) => whiteList.indexOf(path) >= 0;

  return (
    <Route
      path={props.path}
      exact
      render={(prop) =>
        isWhiteList(props.path) ? (
          <>{React.createElement(props.component, prop)}</>
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

const renderRouter = (routes) => {
  let arr = [];
  routes.forEach((route) => {
    const tmpRoute = route.meta.isPrivate ? (
      <PrivateRoute
        exact
        path={route.path}
        component={route.component}
        key={route.name}
      />
    ) : (
      <WhiteListRoute
        path={route.path}
        component={route.component}
        key={route.name}
      />
    );

    arr.push(tmpRoute);
    if (route.children) {
      const newRoute = route.children.map((item) => {
        item.path = route.path.concat(item.path);
        return item;
      });
      arr = arr.concat(renderRouter(newRoute));
    }
  });
  return arr;
};

export const routes = () => {
  return (
    <Switch>
      {renderRouter(appRouter).map((render) => render)}
      <Route exact path="/" component={Home} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default routes;
