import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../api/BaseRequest";

export const listPhone = createAsyncThunk("list_phone", async () => {
  const { list_phone } = await get("list_phone");
  return list_phone;
});

export const listNotify = createAsyncThunk("list_notify", async () => {
  const notify = await get("notify");
  return notify;
});

export const reload = createAsyncThunk("reload_list_notify", async () => {
  const { notify } = await get("reload");
  return notify;
});

const initialState = {
  loading: false,
  listPhones: [],
  infoNotify: {
    notify: "",
    listNotify: [],
  },
};

const ListPhoneSlices = createSlice({
  name: "listPhone",
  initialState,
  extraReducers: (builder) => {
    //listbanner
    builder.addCase(listPhone.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listPhone.fulfilled, (state, action) => {
      state.listPhones = action.payload;
      state.loading = false;
    });
    builder.addCase(listPhone.rejected, (state) => {
      state.loading = false;
    });
    //listnotify
    builder.addCase(listNotify.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listNotify.fulfilled, (state, action) => {
      state.infoNotify = action.payload;
      state.loading = false;
    });
    builder.addCase(listNotify.rejected, (state) => {
      state.loading = false;
    });
    //listnotify
    builder.addCase(reload.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(reload.fulfilled, (state, action) => {
      state.infoNotify.notify = action.payload;
      state.loading = false;
    });
    builder.addCase(reload.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ListPhoneSlices.reducer;
