import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Upload,
  message,
  Spin,
  Space,
  notification,
} from "antd";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { patch } from "../../../api/BaseRequest";
import { storage } from "../../../utils/firebase";
import "./banner.css";
import {
  listBannerId,
  setLoadingBanner,
} from "../../../utils/redux/slices/BannerSlices";

const EditBanner = () => {
  //state
  const { id } = useParams();
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState();
  const [fileSource, setFileSource] = useState();
  const [bannerUrl, setBannerUrl] = useState("");
  const history = useHistory();

  //redux
  const dispatch = useDispatch();
  const { bannerId, loading } = useSelector((state) => state.listBanner);

  useEffect(() => {
    dispatch(listBannerId(id));
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      alt: bannerId.alt,
      url: bannerId.url,
    });
  }, [bannerId]);

  const onFinish = (value) => {
    const newData = {
      ...bannerId,
      ...value,
      src: bannerUrl || bannerId.src,
    };
    patchBannerAPI(newData);
  };

  const patchBanner = (data) => patch(`banner/${bannerId._id}`, data);

  const { mutate: patchBannerAPI, isLoading: isPatchingBanner } = useMutation(
    patchBanner,
    {
      onSuccess: (data) => {
        history.push("/admin/banner");
        notification.success({ message: data.message });
      },
      onError: (error) => {
        notification.error({ message: error.response.data.message });
      },
    }
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isLt10M) {
      notification.error({ message: "Ảnh tải lên cần dưới 10MB" });
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({ message: "Bạn cần nhập ảnh trong ô này" });
    }

    return isLt10M, isJpgOrPng;
  };

  useEffect(() => {
    if (!fileSource) return;
    const storageRef = ref(storage, fileSource?.name);
    const uploadAvatar = uploadBytesResumable(storageRef, fileSource);
    dispatch(setLoadingBanner(true));
    uploadAvatar.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadAvatar.snapshot.ref).then((downloadURL) => {
          setBannerUrl(downloadURL);
          dispatch(setLoadingBanner(false));
        });
      }
    );
  }, [fileSource]);

  const handleChange = (info) => {
    if (info.file.originFileObj.size / 1048576 <= 10) {
      setFileSource(info.file.originFileObj);
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
    }
  };

  const handleCancel = () => {
    history.push(`/admin/banner`);
  };

  return (
    <Spin spinning={isPatchingBanner} tip="Đang tải.....">
      <Row>
        <Col className="form-wrapper" xl={20}>
          <Col className="create-form-title">
            <Typography.Title level={3}>Chỉnh sửa banner</Typography.Title>
          </Col>
          <Col xl={24}>
            <Form
              className="create-form"
              name="create-form"
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                className="create-form-item"
                name="alt"
                label="Tên banner"
                rules={[
                  {
                    required: true,
                    message: "Cần điền trường này",
                  },
                ]}
              >
                <Input placeholder="Tên banner..." />
              </Form.Item>

              <Form.Item
                className="create-form-item"
                name="url"
                label="Url banner"
              >
                <Input placeholder="Url banner....." />
              </Form.Item>

              <Form.Item
                className="create-form-item"
                name="src"
                label="Banner"
                rules={[
                  {
                    required: bannerId.src ? false : true,
                    message: "Cần điền trường này",
                  },
                ]}
              >
                <Upload
                  name="src"
                  listType="picture"
                  className="avatar-uploader"
                  showUploadList={false}
                  maxCount={1}
                  action="http://localhost:3000"
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {imageUrl ? (
                  <Spin spinning={!bannerUrl}>
                    <img src={bannerUrl} style={{ height: "200px" }} />
                  </Spin>
                ) : (
                  <img src={bannerId.src} style={{ height: "200px" }} />
                )}
              </Col>

              <Col xl={24}>
                <Space className="groupButton">
                  <Button htmlType="submit" type="primary" loading={loading}>
                    Chỉnh sửa
                  </Button>
                  <Button onClick={() => handleCancel()}>Quay lại</Button>
                </Space>
              </Col>
            </Form>
          </Col>
        </Col>
      </Row>
    </Spin>
  );
};

export default EditBanner;
