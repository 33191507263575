export const pathnameCode = (idName) => {
  let codeName;
  switch (idName) {
    case "internet-wifi":
      codeName = 1;
      break;
    case "mobile":
      codeName = 3;
      break;
    case "viettel_sign":
      codeName = 4;
      break;
    case "e_invoices":
      codeName = 5;
      break;
    case "devides":
      codeName = 6;
      break;
    case "company":
      codeName = 7;
      break;
    case "televisons":
      codeName = 2;
      break;
    default:
      codeName = 1;
      break;
  }
  return codeName;
};

export const titleName = (id) => {
  let title;
  switch (id) {
    case "internet-wifi":
      title = "Internet Wifi";
      break;
    case "mobile":
      title = "Mobile";
      break;
    case "viettel_sign":
      title = "Viettel Sign";
      break;
    case "e_invoices":
      title = "E Invoices";
      break;
    case "devides":
      title = "Device";
      break;
    case "company":
      title = "Company";
      break;
    case "televisons":
      title = "Televisons";
      break;
    default:
      title = "Internet wifi";
      break;
  }
  return title;
};

export const titleNamePage = (id) => {
  let title;
  switch (id) {
    case "internet-wifi":
      title = "Internet Wifi";
      break;
    case "mobile":
      title = "Điện thoại";
      break;
    case "viettel_sign":
      title = "Chữ ký viettel";
      break;
    case "e_invoices":
      title = "Hóa đơn điện tử";
      break;
    case "devides":
      title = "Thiết bị";
      break;
    case "company":
      title = "Doanh nghiệp";
      break;
    case "televisons":
      title = "Truyền hình";
      break;
    default:
      title = "Internet wifi";
      break;
  }
  return title;
};

export const subPathname = (key) => {
  const config = "/";
  const firstIndex = key.indexOf(config);
  const lastIndex = key.lastIndexOf(config);
  return key.substring(firstIndex, lastIndex);
};

export const paginationTable = {
  defaultCurrent: 1,
  defaultPageSize: 5,
};

export const titleNameCustom = (code) => {
  let name;
  switch (Number(code)) {
    case 1:
      name = "Câu hỏi thường gặp";
      break;
    case 2:
      name = "Tìm kiếm cửa hàng";
      break;
    case 3:
      name = "Phản ánh, góp ý";
      break;
    case 4:
      name = "Video hướng dẫn";
      break;
    default:
      name = "Câu hỏi thường gặp";
  }
  return name;
};

export const titleNameCustomClient = (url) => {
  let name;
  switch (url) {
    case "cau-hoi-thuong-gap":
      name = "Câu hỏi thường gặp";
      break;
    case "tim-kiem-cua-hang":
      name = "Tìm kiếm cửa hàng";
      break;
    case "phan-anh-gop-y":
      name = "Phản ánh, góp ý";
      break;
    case "video-huong-dan":
      name = "Video hướng dẫn";
      break;
    default:
      name = "Câu hỏi thường gặp";
  }
  return name;
};

export const urlConvertCode = (url) => {
  let titleCode;
  switch (url) {
    case "cau-hoi-thuong-gap":
      titleCode = 1;
      break;
    case "tim-kiem-cua-hang":
      titleCode = 2;
      break;
    case "phan-anh-gop-y":
      titleCode = 3;
      break;
    case "video-huong-dan":
      titleCode = 4;
      break;
    default:
      titleCode = 1;
  }
  return titleCode;
};
