/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  htmlNoData,
  nameCategoryPage,
  codeTitlePage,
  subNameData,
} from "../../../helper/dataPostManagement";

import { listTitlePost } from "../../../utils/redux/slices/TitlePostSlices";

import styles from "../detail/style.css";
import { postCategorys } from "../../../utils/redux/slices/PostSlice";

const Detail = () => {
  //redux
  const dispatch = useDispatch();
  const { postCategory } = useSelector((state) => state.listPosts);
  const { listTitle } = useSelector((state) => state.listTitlePost);

  const { urlDetail } = useParams();

  const refContent = useRef();
  const [valueSubCategory, setValueSubCategory] = useState("");
  const [hiddenConten, setHiddenContent] = useState(false);

  window.scrollTo(0, 0);

  useEffect(() => {
    if (listTitle) {
      setValueSubCategory(listTitle[0]?.postTitle);
      dispatch(
        postCategorys({
          category: listTitle[0]?.title,
          subCategory: listTitle[0]?.valueTitle,
        })
      );
    }
  }, [listTitle]);

  useEffect(() => {
    dispatch(listTitlePost({ title: codeTitlePage(urlDetail) }));
  }, [urlDetail]);

  useEffect(() => {
    refContent.current.innerHTML = postCategory?.content || htmlNoData;
  }, [postCategory]);

  const handleGetPost = (value) => {
    setValueSubCategory(subNameData(value.valueTitle, listTitle));
    dispatch(
      postCategorys({ category: value.title, subCategory: value.valueTitle })
    );
  };

  return (
    <Row
      className="wrap-detail"
      style={{ margin: "auto", maxWidth: "1400px" }}
      gutter={30}
    >
      <Col md={24} lg={6} sm={24} xs={24}>
        <div className="title" onClick={() => setHiddenContent(!hiddenConten)}>
          {nameCategoryPage(urlDetail)}
        </div>
        {!hiddenConten && (
          <div className="content">
            <ul>
              {listTitle.map((item) => {
                return item.status ? (
                  <li key={item._id}>
                    <a
                      className="titleLabel"
                      onClick={() => handleGetPost(item)}
                    >
                      {item.postTitle}
                    </a>
                  </li>
                ) : (
                  ""
                );
              })}
            </ul>
          </div>
        )}
      </Col>
      <Col md={24} lg={18} sm={24} xs={24} style={{ minHeight: "600px" }}>
        <div className="box">
          <div className="box-title">
            <h2 className="heading">{valueSubCategory}</h2>
          </div>
          <div className="box-content" ref={refContent}></div>
        </div>
      </Col>
    </Row>
  );
};

export default Detail;
