import React from "react";
import { Dialog } from "@mui/material";
import { useMutation } from "react-query";
import { Form, message, Input, Spin, notification } from "antd";
import moment from "moment";
import { io } from "socket.io-client";

import { post } from "../../api/BaseRequest";

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
});

const ModalsRegisterDataPackage = ({ category, open, setOpen, content }) => {
  const postPhone = (data) => post(`list_phone`, data);

  const { mutate: postPhoneAPI, isLoading: isPostingPhone } = useMutation(
    postPhone,
    {
      onSuccess: (data) => {
        setOpen(false);
        socket.emit("phone");
        notification.success({
          message: "Đăng ký thành công",
          description:
            "Admin sẽ sớm liên hệ với bạn, cảm ơn bạn đã đăng ký dịch vụ của viettel",
        });
      },
      onError: (error) => {
        message
          .loading("Đang gửi thông tin", 1.5)
          .then(() => message.error(`${error.response.data.message}`));
      },
    }
  );

  const onFinish = (data) => {
    const newData = {
      ...data,
      title: category.toLowerCase(),
      name: content,
      registerDate: moment().format(),
    };
    postPhoneAPI(newData);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <div
        className="modal modal--data data-pack-register-not-login"
        style={{ display: "inline-block" }}
      >
        <div className="modal__content" style={{ height: "300px" }}>
          <div className="modal__header">
            <a href="#close" rel="modal:close" className="modal__close">
              <i className="icon-close"></i>
            </a>
          </div>
          <div className="modal__body">
            <div className="modal-data">
              <Spin spinning={isPostingPhone}>
                <Form onFinish={onFinish}>
                  <p className="modal-data__title">Xác nhận</p>
                  <div
                    className="modal-data__content"
                    style={{ paddingTop: 10 }}
                  >
                    <p
                      className="modal-data__des"
                      style={{ paddingBottom: 20 }}
                    >{`Bạn muốn đăng ký gói cước ${content}?`}</p>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Bạn cần điền số điện thoại",
                        },
                        {
                          pattern: new RegExp(`^(84|0[3|5|7|8|9])+([0-9]{8})$`),
                          message: "Số điện thoại không đúng",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Nhập số điện thoại*"
                        className="form-control"
                      />
                    </Form.Item>
                  </div>
                  <div className="modal-data__btn" style={{ paddingTop: 30 }}>
                    <button
                      htmlType="submit"
                      className="button button--primary"
                      style={{ maxWidth: "322px" }}
                    >
                      Đăng ký
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="spinner"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="svg-inline--fa fa-spinner fa-w-16 fa-pulse fa-lg"
                        style={{ display: "none" }}
                      >
                        <path
                          fill="currentColor"
                          d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                          className=""
                        ></path>
                      </svg>
                    </button>
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalsRegisterDataPackage;
