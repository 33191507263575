import { useHistory } from "react-router-dom";

const SupportCustomer = () => {
  const history = useHistory();
  return (
    <div className="support-customer">
      <div className="content">
        <p className="title-home">Hỗ trợ khách hàng</p>
        <ul className="support-customer__list">
          <li
            className="support-customer__item"
            onClick={() => history.push("/custom/cau-hoi-thuong-gap")}
          >
            <img
              src="https://viettel.vn/images_content/support-1.png"
              alt="img"
            />

            <div className="support-customer__info">
              <h3 className="support-customer__name">Câu hỏi thường gặp</h3>
            </div>
          </li>
          <li
            className="support-customer__item"
            onClick={() => history.push("/custom/tim-kiem-cua-hang")}
          >
            <img
              src="https://viettel.vn/images_content/support-2.png"
              alt="img"
            />

            <div className="support-customer__info">
              <h3 className="support-customer__name">Tìm kiếm cửa hàng</h3>
            </div>
          </li>
          <li
            className="support-customer__item"
            onClick={() => history.push("/custom/phan-anh-gop-y")}
          >
            <img
              src="https://viettel.vn/images_content/support-3.png"
              alt="img"
            />

            <div className="support-customer__info">
              <h3 className="support-customer__name">Phản ánh góp ý</h3>
            </div>
          </li>
          <li
            className="support-customer__item"
            onClick={() => history.push("/custom/video-huong-dan")}
          >
            <img
              src="https://viettel.vn/images_content/support-4.png"
              alt="img"
            />

            <div className="support-customer__info">
              <h3 className="support-customer__name">Video hướng dẫn</h3>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SupportCustomer;
