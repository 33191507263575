import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { listBanner } from "../../utils/redux/slices/BannerSlices";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Banner = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listBanner());
  }, []);
  const { listBanners } = useSelector((state) => state.listBanner);

  return (
    <div
      className="banner-custom banner-slideshow mobile owl-carousel owl-theme clear-padding owl-loaded owl-drag"
      // style={{ marginTop: "74px" }}
    >
      <div className="owl-stage-outer">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="owl-stage"
        >
          {listBanners.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="owl-item cloned" style={{ width: "100%" }}>
                <div className="item">
                  <a href={item.url}>
                    <div
                      className="img-banner-home"
                      style={{
                        backgroundImage: `url(${item.src})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Banner;
