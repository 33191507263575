import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css";
import ModalsRegisterDataPackage from "../Modals/ModalsRegisterDataPackage";

const SliderHome = ({ category, data, linkDetail }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const swiperRef = useRef(null);

  const handleOpenModal = (text) => {
    setOpen(true);
    setContent(text);
  };

  const handleSeeMore = (name) => {
    setSeeMore(name);
    if (name) {
      swiperRef.current.swiper.autoplay.stop();
    } else {
      swiperRef.current.swiper.autoplay.start();
    }
  };

  return (
    <>
      <div className="mobile-service" id={linkDetail}>
        <div className="content">
          <div className="tabs">
            <div className="tabs-top">
              <div className="tabs-info">
                <h2 className="title-home">{category}</h2>
              </div>
              <p className="see-more">
                <Link to={`/service/${linkDetail}`}>Xem tất cả</Link>
              </p>
            </div>
            <div className="sub-tabs">
              <div id="tabs-data">
                <div className="lists slider slider--mobile">
                  <ul className="owl-carousel col3 owl-loaded owl-drag">
                    <div className="owl-stage-outer">
                      <Swiper
                        ref={swiperRef}
                        spaceBetween={30}
                        slidesPerView={3}
                        loop={true}
                        autoplay={{
                          delay: 4500,
                          disableOnInteraction: false,
                        }}
                        pagination={{
                          clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="owl-stage"
                      >
                        {data.map((item, index) => (
                          <SwiperSlide key={index}>
                            <div
                              className="owl-item"
                              style={{ width: "392px" }}
                            >
                              <li className="lists-slider__item">
                                <div className="data-pack">
                                  <div
                                    style={{
                                      backgroundImage: `url(${item.src})`,
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      height: "250px",
                                    }}
                                  ></div>
                                  <div className="data-pack__intro">
                                    <div className="data-pack__top">
                                      <h4 className="data-pack__name">
                                        {item.alt}
                                      </h4>
                                      <div className="data-pack__price">
                                        <h4
                                          className="data-pack__price-value"
                                          style={{
                                            fontWeight: "bold",
                                            lineHeight: "36px",
                                            fontSize:
                                              item.price.toString().length <= 7
                                                ? 18
                                                : item.price.toString()
                                                    .length >= 9 && 13,
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {item.price.toLocaleString()}Đ
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="data-pack__info">
                                      <p
                                        className="data-pack__des"
                                        style={{ minHeight: "95px" }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.desc,
                                        }}
                                      />

                                      <p
                                        style={
                                          seeMore === item.alt
                                            ? {
                                                minHeight: "100px",
                                                paddingTop: "7px",
                                                lineHeight: "1.6",
                                              }
                                            : {
                                                minHeight: "75px",
                                                paddingTop: "7px",
                                                lineHeight: "1.6",
                                                overflow: "hidden",
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: "3",
                                              }
                                        }
                                        dangerouslySetInnerHTML={{
                                          __html: item.content,
                                        }}
                                      />
                                    </div>
                                    <div className="data-pack__last">
                                      {seeMore === item.alt ? (
                                        <a
                                          onClick={() => handleSeeMore("")}
                                          className="data-pack__see-more"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Thu gọn
                                        </a>
                                      ) : (
                                        <a
                                          onClick={() =>
                                            handleSeeMore(item.alt)
                                          }
                                          className="data-pack__see-more"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Xem chi tiết
                                        </a>
                                      )}
                                      <div className="data-pack__btn">
                                        <a
                                          onClick={() =>
                                            handleOpenModal(item.alt)
                                          }
                                          className="button button--primary"
                                        >
                                          Đăng ký
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </ul>
                </div>
                {/* tabs-data-mobile */}
                <div className="mobile-data">
                  <ul className="mobile-data__list">
                    {data.map((item, index) => (
                      <li className="mobile-data__item" key={index}>
                        <div className="data-pack">
                          <a
                            href={item.href}
                            className="data-pack__images img-hover"
                          >
                            <img src={item.src} alt={item.alt} />
                          </a>
                          <div className="data-pack__intro">
                            <div className="data-pack__top">
                              <h4 className="data-pack__name">
                                <a href={item.href} className="data-pack__link">
                                  {item.alt}
                                </a>
                              </h4>
                              <div className="data-pack__price">
                                <span
                                  className="data-pack__price-value"
                                  style={{ fontSize: 20 }}
                                >
                                  {item.price.toLocaleString()} Đ
                                </span>
                              </div>
                            </div>
                            <div className="data-pack__info">
                              <p
                                className="data-pack__des data-pack__des--1line"
                                style={{ minHeight: "40px" }}
                                dangerouslySetInnerHTML={{
                                  __html: item.desc,
                                }}
                              />

                              <p
                                style={
                                  seeMore === item.alt
                                    ? {
                                        minHeight: "100px",
                                        paddingTop: "7px",
                                        lineHeight: "1.6",
                                      }
                                    : {
                                        minHeight: "75px",
                                        paddingTop: "7px",
                                        lineHeight: "1.6",
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: "3",
                                      }
                                }
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              />
                            </div>
                            <div className="data-pack__last">
                              {seeMore === item.alt ? (
                                <a
                                  onClick={() => handleSeeMore("")}
                                  className="data-pack__see-more"
                                  style={{ cursor: "pointer" }}
                                >
                                  Thu gọn
                                </a>
                              ) : (
                                <a
                                  onClick={() => handleSeeMore(item.alt)}
                                  className="data-pack__see-more"
                                  style={{ cursor: "pointer" }}
                                >
                                  Xem chi tiết
                                </a>
                              )}

                              <div className="data-pack__btn">
                                <a
                                  className="button button--primary"
                                  onClick={() => handleOpenModal(item.alt)}
                                >
                                  Đăng ký
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalsRegisterDataPackage
        open={open}
        setOpen={setOpen}
        content={content}
        category={category}
      />
    </>
  );
};

export default SliderHome;
