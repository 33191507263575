/* eslint-disable default-case */
import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  Spin,
  Row,
  Col,
  Typography,
  Button,
  Table,
  Space,
  message,
  Modal,
} from "antd";
import { useMutation } from "react-query";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { del } from "../../../api/BaseRequest";
import { listProduct } from "../../../utils/redux/slices/ProductSlice";
import { paginationTable, titleNamePage } from "../../../utils";

const { confirm } = Modal;

const ProductManagement = () => {
  const html = useRef([]);
  //columns table
  const headCells1 = [
    {
      title: "STT",
      dataIndex: "index",
      render: (d, _, index) => <span>{index + 1}</span>,
      width: 80,
    },
    {
      title: "Tên dịch vụ",
      dataIndex: "alt",
      key: "alt",
      width: "15%",
    },
    {
      title: "Giá tiền",
      dataIndex: "price",
      key: "price",
      ellipsis: true,
      width: "10%",
      render: (val, key) => {
        const price = val.toLocaleString();
        return <text>{price}đ</text>;
      },
    },
    {
      title: "Mô tả",
      dataIndex: "desc",
      key: "desc",
      ellipsis: true,
      width: "20%",
      render: (data) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      key: "content",
      ellipsis: true,
      width: "30%",
      render: (data) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    },
    {
      title: "Hình ảnh",
      dataIndex: "src",
      key: "src",
      render: (val, key) => {
        return <img src={val} alt={key.alt} />;
      },
      width: "8%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "_id",
      width: "20%",
      render: (val, key) => (
        <>
          <Space>
            <Button type="primary" onClick={() => changePage(key._id)}>
              Chỉnh sửa
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(key)}
            >
              Xóa
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const showDeleteConfirm = (key) => {
    confirm({
      title: "Bạn có muốn xóa thông tin ?",
      icon: <ExclamationCircleOutlined />,
      content: key.alt,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        delProductAPI(key._id);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //redux
  const dispatch = useDispatch();
  const { listProducts, loading } = useSelector((state) => state.listProducts);

  //state
  const { link } = useParams();
  const history = useHistory();
  const delProduct = (id) => del(`${link}/${id}`);

  useEffect(() => {
    dispatch(listProduct(link));
  }, [link]);

  const { mutate: delProductAPI, isLoading: isDeletingProdut } = useMutation(
    delProduct,
    {
      onSuccess: (data) => {
        dispatch(listProduct(link));
        message.success(`${data.message}`);
      },
      onError: (error) => {
        message.error(`${error.response.data.message}`);
      },
    }
  );

  const changePage = (id) => {
    history.push(`/admin/product/${link}/${id}`);
  };

  return (
    <Spin spinning={isDeletingProdut}>
      <Row>
        <Col className="table-product" xl={20}>
          <Col className="table-titles" xl={22}>
            <Typography.Title level={3}>{`Quản lý ${titleNamePage(
              link
            )}`}</Typography.Title>
            <Button type="primary">
              <Link to={`/admin/product/${link}/create`}>Tạo mới</Link>
            </Button>
          </Col>
          <Col style={{ marginBottom: 30 }}>
            <Table
              bordered
              columns={headCells1}
              dataSource={listProducts}
              loading={loading}
              pagination={paginationTable}
            />
          </Col>
        </Col>
      </Row>
    </Spin>
  );
};

export default ProductManagement;
