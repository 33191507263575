import Banner from "../../components/HomePage/Banner";
import SliderHome from "../../components/HomePage/SliderHome";
import SupportCustomer from "../../components/HomePage/SupportCustomer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  listEInvoice,
  listInternetE,
  listMobileService,
  listSolution,
  listTeleDevi,
  listTelevison,
  listViettelSign,
} from "../../utils/redux/slices/ProductDisplaySlice";

const Home = () => {
  //redux
  const dispatch = useDispatch();
  const {
    e_invoice,
    internetE,
    mobile_service,
    solution,
    tele_devi,
    televisons,
    viettel_sign,
    search,
  } = useSelector((state) => state.listAllProduct);

  //callAPI
  useEffect(() => {
    dispatch(listEInvoice({ search }));
    dispatch(listInternetE({ search }));
    dispatch(listMobileService({ search }));
    dispatch(listSolution({ search }));
    dispatch(listTeleDevi({ search }));
    dispatch(listTelevison({ search }));
    dispatch(listViettelSign({ search }));
  }, [search, dispatch]);

  return (
    <>
      <Banner />
      <SliderHome
        category="INTERNET WIFI"
        data={internetE}
        linkDetail="internet-viettel"
      />
      <SliderHome
        category="TRUYỀN HÌNH"
        data={televisons}
        linkDetail="truyen-hinh-viettel"
      />
      <SliderHome
        category="DI ĐỘNG"
        data={mobile_service}
        linkDetail="di-dong-viettel"
      />
      <SliderHome
        category="CHỮ KÝ SỐ VIETTEL"
        data={viettel_sign}
        linkDetail="chu-ky-so-viettel"
      />
      <SliderHome
        category="HÓA ĐƠN ĐIỆN TỬ"
        data={e_invoice}
        linkDetail="hoa-don-dien-tu-viettel"
      />
      <SliderHome
        category="THIẾT BỊ"
        data={tele_devi}
        linkDetail="thiet-bi-dien-thoai"
      />
      <SliderHome
        category="DOANH NGHIỆP"
        data={solution}
        linkDetail="giai-phap-doanh-nghiep"
      />
      <SupportCustomer />
    </>
  );
};

export default Home;
