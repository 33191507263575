/* eslint-disable default-case */
import {
  Row,
  Col,
  Typography,
  Button,
  Space,
  Table,
  Modal,
  message,
  Spin,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";

import { nameCategory, subNameData } from "../../../helper/dataPostManagement";
import { del } from "../../../api/BaseRequest";
import { listPost } from "../../../utils/redux/slices/PostSlice";
import { listTitlePost } from "../../../utils/redux/slices/TitlePostSlices";

import "./post.css";

const { confirm } = Modal;

const ListPost = () => {
  //state
  const history = useHistory();
  const html = useRef([]);

  //columns table
  const headCells1 = [
    {
      title: "Tên dịch vụ",
      dataIndex: "category",
      key: "category",
      render: (data) => <span>{nameCategory(Number(data))}</span>,
    },
    {
      title: "Nâng cao",
      dataIndex: "subCategory",
      key: "subCategory",
      render: (data) => <span>{subNameData(data, listTitle)}</span>,
    },
    {
      title: "Nội dung (tóm tắt)",
      dataIndex: "content",
      key: "content",
      render: (val, key, index) => {
        return (
          <div
            ref={(el) => (html.current[index] = el)}
            style={{ height: 200, overflow: "scroll" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "_id",
      render: (val, key) => (
        <>
          <Space>
            <Button type="primary" onClick={() => editPost(key._id)}>
              Chỉnh sửa
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(key)}
            >
              Xóa
            </Button>
          </Space>
        </>
      ),
    },
  ];

  //redux
  const dispatch = useDispatch();
  const { listPosts } = useSelector((state) => state.listPosts);
  const { listTitle } = useSelector((state) => state.listTitlePost);

  useEffect(() => {
    listPosts.forEach((item, index) => {
      html.current[index].innerHTML = item.content;
    });
  }, [listPosts]);

  useEffect(() => {
    dispatch(listPost());
    dispatch(listTitlePost());
  }, []);

  //Modal
  const showDeleteConfirm = (key) => {
    confirm({
      title: "Bạn có muốn xóa thông tin ?",
      icon: <ExclamationCircleOutlined />,
      content: key.alt,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        delBannerAPI(key._id);
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //Updaload data
  const delBanner = (id) => del(`posts/${id}`);

  const { mutate: delBannerAPI, isLoading: isDeletingBanner } = useMutation(
    delBanner,
    {
      onSuccess: (data) => {
        dispatch(listPost());
        message.success(`${data.message}`);
      },
      onError: (error) => {
        message.error(`${error.response.data.message}`);
      },
    }
  );

  //history push id
  const editPost = (id) => {
    history.push(`/admin/posts/${id}`);
  };

  return (
    <Spin spinning={isDeletingBanner}>
      <Row>
        <Col className="table-product" xl={20}>
          <Col className="table-titles" xl={22}>
            <Typography.Title level={3}>Quản lý bài viết</Typography.Title>
            <Button type="primary">
              <Link to="/admin/posts/create">Tạo mới</Link>
            </Button>
          </Col>
          <Col style={{ marginBottom: 30 }}>
            <Table
              bordered
              columns={headCells1}
              dataSource={listPosts}
              loading={false}
              pagination={{
                defaultCurrent: 1,
              }}
            />
          </Col>
        </Col>
      </Row>
    </Spin>
  );
};
export default ListPost;
