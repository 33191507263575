import React, { useEffect } from "react";
import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

import { useHistory, useParams } from "react-router-dom";
import { listTitlePost } from "../../../utils/redux/slices/TitlePostSlices";
import {
  paginationTable,
  pathnameCode,
  titleNamePage,
} from "../../../utils/index";

import "./index.css";

const TitleManagement = () => {
  const column = [
    {
      title: "STT",
      dataIndex: "index",
      render: (d, _, index) => <span>{index + 1}</span>,
    },
    {
      title: "Tên tiêu đề",
      dataIndex: "postTitle",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      render: (data) => <span>{moment(data).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Ngày chỉnh sửa",
      dataIndex: "updatedAt",
      render: (data) => <span>{moment(data).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      render: (data) => (
        <Tag className="tagStatus" color={data === 1 ? "success" : "default"}>
          {data === 1 ? "Hiển thị" : "Ẩn"}
        </Tag>
      ),
    },
    {
      title: "Action",
      render: (key) => (
        <Space>
          <Button
            className="btnAction"
            type="primary"
            onClick={() => history.push(`${id}/edit/${key._id}`)}
          >
            Chỉnh sửa
          </Button>
          <Button
            className="btnAction"
            type="primary"
            danger
            onClick={() => handleEditStatus(key)}
          >
            {key.status === 1 ? "Ẩn" : "Hiển thị"}
          </Button>
          <Popconfirm
            title="Bạn có chắc chắn muốn xóa tiêu đề không ?"
            okText="Chắc chắn"
            cancelText="Hủy"
            onConfirm={() => handleDelete(key)}
          >
            <Button className="btnAction">Xóa</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  //redux
  const dispatch = useDispatch();
  const { listTitle, loading } = useSelector((state) => state.listTitlePost);

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(listTitlePost({ title: pathnameCode(id) }));
  }, [id, dispatch]);

  const handleEditStatus = (data) =>
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/patchTitle`,
        {
          status: data.status === 1 ? 0 : 1,
        },
        {
          params: {
            title: data.title,
            id: data._id,
          },
        }
      )
      .then(() => {
        notification.success({ message: "Đang tải dữ liệu" });
        dispatch(listTitlePost({ title: pathnameCode(id) }));
      })
      .catch(() => {
        notification.error({ message: "Tiêu đề không tồn tại" });
      });

  const handleDelete = (data) =>
    axios
      .delete(`${process.env.REACT_APP_API_URL}/delTitle`, {
        params: {
          title: data.title,
          id: data._id,
        },
      })
      .then(() => {
        notification.success({ message: "Xóa tiêu đề thành công" });
        dispatch(listTitlePost({ title: pathnameCode(id) }));
      })
      .catch(() => {
        notification.error({ message: "Tiêu đề không tồn tại" });
      });

  return (
    <Row>
      <Col xxl={20} className="titleManagement">
        <Row>
          <Typography.Title level={3}>{`Quản lý danh mục ${titleNamePage(
            id
          )}`}</Typography.Title>
        </Row>
        <Row>
          <Space size={20}>
            <Button
              type="primary"
              className="btnAdd"
              onClick={() => history.push(`${id}/create`)}
            >
              Thêm mới tiêu đề
            </Button>
          </Space>
        </Row>

        <Table
          bordered
          columns={column}
          dataSource={listTitle}
          loading={loading}
          pagination={paginationTable}
        />
      </Col>
    </Row>
  );
};

export default TitleManagement;
